<h2 class="heading" [ngClass]="{'emailblock' : screenTobeAppear === 'email', 'loginblock' : screenTobeAppear === 'login'}">
  {{dataCommService.touchPointHeading}}
  <span *ngIf="dataCommService.touchPointSubHeading!=='Changed'">{{dataCommService.touchPointSubHeading}}</span>
  <span *ngIf="dataCommService.touchPointSubHeading==='Changed'" style="display: inline;">{{dataCommService.touchPointSubHeading}}</span>
</h2>

<perfect-scrollbar>
<div class="formSection" *ngIf="screenTobeAppear !== 'currency'" [ngClass]="{'signin': screenTobeAppear === 'login' }">
  <form [formGroup]="loginSignupForm">
    <ul>
      <li>
        <label>Email*</label>
        <input type="text" placeholder="Enter your email" pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$" formControlName="email" required (keyup)="validateField('email')" (focusout)="sendTrackEvent('[Builder] Email Initiated')">
        <div class="errorMsg" *ngIf="(loginClicked && invalidEmail && !invalidEmailErrMeggase) || (email.touched && checkIfEmailValid())">Please enter valid email</div>
        <div class="errorMsg" *ngIf="loginClicked && invalidEmail && invalidEmailErrMeggase">{{ invalidEmailErrMeggase }}</div>
      </li>
      <ng-container *ngIf="screenTobeAppear === 'signup'">
        <li>
          <label>Name*</label>
          <input type="text" placeholder="Enter your name" formControlName="name" required (keyup)="validateField('name')" (focusout)="sendTrackEvent('[Builder] Name Initiated')">
          <!-- <div class="errorMsg" *ngIf="invalidName && !(name.touched && name.invalid)">Please enter name</div> -->
          <div *ngIf="(loginClicked && invalidName) || (name.touched && name.invalid)">
            <span *ngIf="name.errors['required']" class="errorMsg"> Name cannot be blank </span>
          </div>
        </li>
        <!-- <li>
          <label>Phone Number*</label>
          <international-phone-number #mobileNumber (keyup)="validateField('phone')" placeholder="Mobile Number" formControlName="phone"
                                      [maxlength]="20" [defaultCountry]="authService.countryNameCode" required>
          </international-phone-number>
          <span class="errorMsg" *ngIf="(loginClicked && invalidPhone) || (phone.touched && phone.invalid)">Please enter valid contact</span>
        </li> -->
        <li><label>Phone Number*</label>
          <ngx-intl-tel-input #mobileNumber (keyup)="validateField('phone')" [cssClass]="'custom'"
            [enablePlaceholder]="true" [searchCountryFlag]="true" [separateDialCode]="true" [maxLength]="20"
            [selectedCountryISO]="authService.countryNameCode" formControlName="phone" (countryChange)="onCountryChange($event)" required>
          </ngx-intl-tel-input>
          <span class="errorMsg" *ngIf="(loginClicked && invalidPhone) || (phone.touched && phone.invalid)">
            Please enter valid contact
          </span>
        </li>
        <li>
          <label>Currency*</label>
          <div class="currencyDropdown">
            <div class="selectedCurrency" *ngIf="!selectedCurrency">Select currency</div>
            <div class="selectedCurrency" *ngIf="selectedCurrency">{{ selectedCurrency.name }}</div>
            <div class="currencyListing" *ngIf="showCurrencyDropdown">
              <perfect-scrollbar>
                <!-- <ol>
                  <li *ngFor="let currency of currencies" (click)="selecyCurrecy(currency, $event)">
                    <div class="currencyicon">
                      <img [src]="currency.icon_image_file_url" />
                    </div>
                    <div class="currencyname">{{ currency.name }}</div>
                  </li>
                </ol> -->
                <div class="currencyBlock" *ngFor="let currency of currencies" (click)="selecyCurrecy(currency, $event)">
                    <div class="currencyicon">
                      <img [src]="currency.icon_image_file_url" />
                    </div>
                    <div class="currencyname">{{ currency.name }}</div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
          <span class="errorMsg" *ngIf="isRegisterClicked && !selectedCurrency">Please select currency</span>
        </li>
      </ng-container>
      <li *ngIf="screenTobeAppear !== 'email'" class="forgot-pass-bx">
        <label >Password*
          <h5 class="link forgot" *ngIf="screenTobeAppear === 'login'" (click)="forgotPasswordPopup()"><strong style="color: #6C00EA;">Forgot Password?</strong></h5>
        </label>
        <input type="password" placeholder="Enter password" minlength="8" formControlName="password" required (keyup)="validateField('password')" (focusout)="sendTrackEvent('[Builder] Password Initiated')">
        <!-- <span *ngIf="invalidPassword && !(password.touched && password.invalid)" class="errorMsg"> Password is not valid</span> -->
        <div *ngIf="(loginClicked && invalidPassword) || (password.touched && password.invalid)">
          <span *ngIf="password.errors['minlength']" class="errorMsg"> Password should contain 8 characters</span>
          <span *ngIf="password.errors['required']" class="errorMsg"> Password cannot be blank </span>
        </div>
      </li>
      <li class="termsCondition" *ngIf="screenTobeAppear === 'signup'">
        <h5 class="link">By continuing, you agree to our <a class="highlight normal-font" href="https://www.builder.ai/terms" target="_blank">Terms and Conditions</a></h5>
        <!--        <p><input type="checkbox" [(ngModel)]="signupTerms" (click)="sendTrackEvent('[Builder] Agree Terms & conditions CTA clicked', $event)" id="termscondition" [ngModelOptions]="{standalone: true}"> <label class="icon-right" for="termscondition">I agree to the <a href="https://www.builder.ai/terms" target="_blank">Terms and Conditions</a></label></p>-->
<!--        <div class="errorMsgDiloge" *ngIf="!signupTerms && loginClicked">-->
<!--          <em class="icon-exclamation-triangle"></em> <strong>Please check this box if you want to proceed</strong>-->
<!--        </div>-->
      </li>
    </ul>
  </form>
  <!--<h5 class="link forgot" *ngIf="screenTobeAppear === 'login'" (click)="forgotPasswordPopup()"><strong style="color: #6C00EA;">Forgot Password?</strong></h5>-->
    <button type="button" class="buttonStyle filGreenBtn" *ngIf="screenTobeAppear !== 'email'" [ngClass]="{'custom-disabled': dataCommService.proceedClickedFromRental}" (click)="loginSignup()">{{btnCtaName}}
      <img *ngIf="dataCommService.proceedClickedFromRental" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
  </button>
  <!-- <button type="button" class="buttonStyle" *ngIf="showLoginScreen && screenTobeAppear !== 'email'" [ngClass]="{'custom-disabled': dataCommService.proceedClickedFromRental}" (click)="loginSignupOnComment()">{{btnCtaName}}
    <img *ngIf="dataCommService.proceedClickedFromRental" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
  </button> -->
  <button type="button" class="buttonStyle filGreenBtn" (click)="checkEmailBeforeSigninSignup()" *ngIf="screenTobeAppear === 'email'">Next</button>
  <h5 class="link" *ngIf="screenTobeAppear === 'signup' || screenTobeAppear === 'email'" (click)="showLoginOrSignup('login')">Already have an account? <span class="highlights">Sign in</span></h5>
  <h5 class="link" *ngIf="screenTobeAppear === 'login'" (click)="showLoginOrSignup('signup')">Don't have an account? <span class="highlights">Sign up</span></h5>
  <div class="divider" *ngIf="screenTobeAppear === 'email' || screenTobeAppear === 'login'" ><span class="txt">Or connect using</span></div>
  <div class="social-signup-wrap" *ngIf="screenTobeAppear === 'email' || screenTobeAppear === 'login'">
    <div class="social-signup">
      <div class="social-entity google" (click)="social_sign('google')">
                <span class="icon">
                    <img src="../../../../../assets/images/google.svg">
                </span>
        <span class="txt">Google</span>
      </div>
      <div class="social-entity facebook" (click)="social_sign('facebook')">
                <span class="icon">
                    <img src="../../../../../assets/images/fb.svg">
                </span>
        <span class="txt">Facebook</span>
      </div>
    </div>
    <p class="link left-align-txt">By continuing, you agree to our&nbsp;<a class="highlights normal-font" href="https://www.builder.ai/terms" target="_blank">Terms and Conditions</a></p>
  </div>
</div>
</perfect-scrollbar>
<div class="back-btn" *ngIf="screenTobeAppear === 'signup' || screenTobeAppear === 'login'" (click)="goToPreviousScreen()"><span class="icon-angle-left"></span> Back </div>
<div class="add-currecy-container" *ngIf="screenTobeAppear === 'currency'">
  <form>
    <ul>
      <li>
        <label>Currency*</label>
        <div class="currencyDropdown">
          <div class="selectedCurrency" *ngIf="!selectedCurrency">Select currency</div>
          <div class="selectedCurrency" *ngIf="selectedCurrency">{{ selectedCurrency.name }}</div>
          <div class="currencyListing" *ngIf="showCurrencyDropdown">
            <perfect-scrollbar>
              <ol>
                <li *ngFor="let currency of currencies" (mousedown)="selecyCurrecy(currency, $event)">
                  <div class="currencyicon">
                    <img [src]="currency.icon_image_file_url" />
                  </div>
                  <div class="currencyname">{{ currency.name }}</div>
                </li>
              </ol>
            </perfect-scrollbar>
          </div>
        </div>
        <span class="errorMsg" *ngIf="!selectedCurrency && loginClicked">Please select currency</span>
      </li>
    </ul>
    <button type="button" class="buttonStyle filGreenBtn" (click)="updateCurrency()">Done</button>
  </form>
</div>
