export class CommentModel {

  id: number;
  body: string;
  position: {
      originX: number,
      originY: number
  };
  created_at: string;
  updated_at: string;
  stamp_mark: string;
  is_owner: boolean;
  commented_by: string;
  commented_on: string;
  replies: Reply[];
  isReplied: boolean;
  target_feature_id: number;
  set_as_deigner_task: boolean;
  userType: string;
  commentType: string;

  constructor() {
      this.id = null;
      this.body = '';
      this.position = {
        originX: 0,
        originY: 0
      };
      this.stamp_mark = null;
  }
}

class Reply {
    id: number;
    body: string;
    commented_by: string;
    created_at: string;
    updated_at: string;
}
