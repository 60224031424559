<div class="error-page" *ngIf="!dataCommService.showLoaderForPrototpeCreation">
  <div class="error-page-content">
    <h1>The page you were looking <br> for does not exist.</h1>

    <div class="full-content">
      <div class="left left-content">
        <p></p>
        <p>If you typed in an URL, double-check the spelling.</p>
      </div>
    </div>
  </div>
</div>
<app-handle-api-errors *ngIf="apiError"></app-handle-api-errors>
<div class="newCurrencyPopUp" *ngIf="dataCommService.showLoaderForPrototpeCreation">
  <div class="loaderBx">
    <img src="../../assets/images/loader_logo.gif"/>
  </div>
</div>

<app-rental-build-card *ngIf="dataCommService.showRentalPaymentFlow && !authService.isCurrencyPresentInUser()"></app-rental-build-card>

