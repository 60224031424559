import { Injectable } from '@angular/core';
import {WindowRefService} from '@core/window-ref.service';
import {environment} from '@env/environment';
/**
 * Now cookie management service
 */
@Injectable({
    providedIn: 'root'
})
export class CookieService {
  public userCookie = null;
  public cookieDomain = null;

  constructor(private window: WindowRefService) { }

  public setCookieDomain() {
    const hostfromurl = window.location.hostname;
    this.userCookie = environment.USER_COOKIE;
    if (hostfromurl === 'localhost') {
      this.cookieDomain = 'localhost';
    } else {
      const separate = hostfromurl.split('.');
      separate.shift();
      const currentDomain = separate.join('.');
      this.cookieDomain = '.' + currentDomain;
    }
  }

  public setAllCookie() {
   /* this.window.nativeDocument.cookie =
    `${this.getCookie(environment.USER_COOKIE)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookieDomain};`;*/
  }

  public setCookie(name: string, value: string, expiryDate: string = '2038-01-19 04:14:07', path: string = '') {
    this.setCookieDomain();
    this.window.nativeDocument.cookie = `${name}=${value};expires=${expiryDate};path=${path}`;
  }

  public getCookie(name: string) {
    const regexp = new RegExp(name + '=([^;]+)');
    return regexp.exec(this.window.nativeDocument.cookie);
  }

  public setLocalStorage(name: string, value: string) {
    localStorage.setItem(name, value);
  }

  public getLocalStorage(name: string) {
    return localStorage.getItem(name);
  }
  public deleteCookie(name: string) {
    this.setCookieDomain();
    document.cookie = `${this.userCookie}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${this.cookieDomain}`;
  }

  public getCookieEmail(name: string) {
    const userCookieData = this.getCookie(name);
    if (userCookieData) {
      const userData = JSON.parse(userCookieData[1]);
      return userData.email;  
    } else {
      return false;
    }
  }
}
