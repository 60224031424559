import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class OverlayWithContentService {
  // Observable string sources
  private showHideSliderSource = new Subject<any>();
  private enableSaveButtonSource = new Subject<any>();
  private enableDeleteButtonSource = new Subject<any>();
  private enableConfirmButtonSource = new Subject<any>();
  private clickedHotspotFromSource = new Subject<any>();
  private clickedConfirmBtnSource = new Subject<any>();
  // create observables vars
  showHideSlider$ = this.showHideSliderSource.asObservable();
  enableSaveButton$ = this.enableSaveButtonSource.asObservable();
  enabeDeleteButton$ = this.enableDeleteButtonSource.asObservable();
  enabeConfirmButton$ = this.enableConfirmButtonSource.asObservable();
  clickedHotspotFrom$ = this.clickedHotspotFromSource.asObservable();
  clickedConfirmBtn$ = this.clickedConfirmBtnSource.asObservable();

  isHotspotHorizontalScroll: boolean = false;
  isHotspotVerticalScroll: boolean = false;
  hotspotImageScrollX: number = 0;
  hotspotImageScrollY: number = 0;
  hotspotImageScale: number = 1;
  hotspotLinkingWrapperStyleObj: any = {};
  perfectScrollBarConfig: any = {};
  /**
   * @method: showHideSliderFun(param)
   * @param: flag, Boolean
   */
  showHideSliderFun(flag: boolean) {
    this.showHideSliderSource.next(flag);
  }
  enableSaveButtonFun(flag: boolean) {
    this.enableSaveButtonSource.next(flag);
  }
  enableDeleteButtonFun(flag: boolean) {
    this.enableDeleteButtonSource.next(flag);
  }
  enableConfirmButtonFun(flag: boolean) {
    this.enableConfirmButtonSource.next(flag);
  }
  clickedHotspotFromFun(value: string) {
    this.clickedHotspotFromSource.next(value);
  }
  clickedConfirmBtnFun() {
    this.clickedConfirmBtnSource.next();
  }
}
