import { Component, OnInit } from '@angular/core';
import {DataService} from '../shared/services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataCommService} from '../shared/services/data-comm.service';

@Component({
  selector: 'app-handle-api-errors',
  templateUrl: './handle-api-errors.component.html',
  styleUrls: ['./handle-api-errors.component.scss']
})
  export class HandleApiErrorsComponent implements OnInit {
  buildCardId: string;
  constructor(private dataCommService: DataCommService,
              private dataService: DataService ,
              private router: Router,
              private route: ActivatedRoute) {

  }

  ngOnInit() {

  }

}
