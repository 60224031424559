
<div class="zero-feature-animation">
    <div class="header">
        <span class="logo">
            <img src="../../../assets/images/buildernow-logo.svg">
        </span>
    </div>
    <div class="zero-feature-container">
        <div class="left-sec">
            <div class="content">
                <h3>Hey, your board is empty!</h3>
                <p>Add features to see your prototype.</p>
                <button (click)="redirectToBuilder()">Add Features Now</button>
            </div>
        </div>
        <div class="right-sec">
            <div class="mac">
                <img src="../../../assets/images/zero-feature-ani-mac.png">
                <div class="main-screen">
                    <div class="cursor"></div>
                    <div class="overlay"></div>
                    <span class="simple-list">
                        <img src="../../../assets/images/zero-feature-ani-list.png">
                        <span class="color-list1">
                            <img src="../../../assets/images/zero-feature-color-list1.png">
                        </span>
                        <span class="color-list2">
                            <img src="../../../assets/images/zero-feature-color-list2.png">
                        </span>
                        <span class="color-list3">
                            <img src="../../../assets/images/zero-feature-color-list3.png">
                        </span>
                    </span>
                    <span class="feature-btn">
                        <img src="../../../assets/images/zero-feature-btn.png">
                    </span>
                    <span class="btn-highlight"></span>
                    <div class="prototype-page">
                        <img src="../../../assets/images/zero-feature-prototype.png">
                        <div class="frame-wrap">
                            <span class="frame frame1">
                                <img src="../../../assets/images/zero-feature-frame1.jpg">
                            </span>
                            <span class="frame frame2">
                                <img src="../../../assets/images/zero-feature-frame2.jpg">
                            </span>
                            <span class="frame frame3">
                                <img src="../../../assets/images/zero-feature-frame3.jpg">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
