import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { EmptyboardComponent } from './emptyboard/emptyboard.component';
import { WipComponent } from './wip/wip.component';
import { TermsComponent } from './terms/terms.component';

const appRoutes: Routes = [
    { path: 'wip', component: WipComponent },
    { path: 'terms',  component: TermsComponent},
    { path: 'createbuildcard',  component: EmptyboardComponent },
    { path: 'reset-password', component: Page404Component },
    { path: ':buildcardId', loadChildren: () => import('./main/main.module').then(m => m.MainModule)},
    { path: ':buildcardId/v1', loadChildren: () => import('./main/main.module').then(m => m.MainModule)},
    { path: ':buildcardId/branding', loadChildren: () => import('./main/branding/branding.module').then(m => m.BrandingModule)},
    { path: ':buildcardId/branding/success', loadChildren: () => import('./main/branding/branding.module').then(m => m.BrandingModule)},
    { path: '**', component: Page404Component }
];


export const AppRoutingModule = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });



