import {Component, OnDestroy, OnInit} from '@angular/core';
import { UtilityService } from '@shared/services/utility.service';
import moment from 'moment';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {DataService} from '../../../../../main/shared/services/data.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-payment-overview',
    templateUrl: './payment-overview.component.html',
    styleUrls: ['./payment-overview.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [   // :enter is alias to 'void => *'
                style({
                    transform: 'translateX(100%)'
                }),
                animate(200, style({
                    transform: 'translateX(0)'
                }))
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate(200, style({
                    transform: 'translateX(100%)'
                }))
            ])
        ]),
        trigger('tooltipAni', [
            transition(':enter', [   // :enter is alias to 'void => *'
                style({
                    opacity:'0',
                    transform: 'translateX(-50%)'
                }),
                animate(200, style({
                    opacity:'1',
                    transform: 'translateX(-50%)'
                }))
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate(200, style({
                    opacity:'0',
                    transform: 'translateX(-50%)'
                }))
            ])
        ])
    ]
})
export class PaymentOverviewComponent implements OnInit, OnDestroy {

    constructor(
        public dataCommService: DataCommService, 
        public dataService: DataService,
        public utilityService: UtilityService) {
    }

    ngOnInit(): void {
        this.dataCommService.fetchBuildCardData();
    }

    getTodayDate() {
        return moment().format('DD-MMM-YY');
    }

    proceedNext(prototypeFlag) {
        if (prototypeFlag === 'professional') {
            this.dataCommService.prototypeInstallment = this.dataCommService.customPrototypeObj.attributes;
            this.dataCommService.prototypeInstallment.currency = (this.dataCommService.customPrototypeObj.attributes.currency.data ? this.dataCommService.customPrototypeObj.attributes.currency.data.attributes : this.dataCommService.customPrototypeObj.attributes.currency);
        } else {
            this.dataCommService.prototypeInstallment = this.dataCommService.tailorMadePrototypeObj.attributes;
            this.dataCommService.prototypeInstallment.currency = (this.dataCommService.tailorMadePrototypeObj.attributes.currency.data ? this.dataCommService.tailorMadePrototypeObj.attributes.currency.data.attributes : this.dataCommService.tailorMadePrototypeObj.attributes.currency);
        }
        this.dataCommService.proceedClickedFromRental = true;
        this.dataCommService.handleNextPrevForRentalFlow();
    }
    viewUnlimitedVersion() {
        this.dataCommService.showViewUnlimitedVersion = !this.dataCommService.showViewUnlimitedVersion;
    }

    ngOnDestroy() {
        this.dataCommService.proceedClickedFromRental = false;
    }

    getDeliveryDate(prototypeFlag) {
        const deliveryDateCounter = (prototypeFlag === 'tailorMade') ? this.dataCommService.tailorMadePrototypeObj.attributes.delivery_time : this.dataCommService.customPrototypeObj.attributes.delivery_time;
        const todaysDate = moment();
        const nextDay = moment(todaysDate);
        let i = 1;
        while (i <= deliveryDateCounter) {
            nextDay.date(nextDay.date() + 1);
            i++;
            // if (![0, 6].includes(nextDay.day())) {
                
            // }
        }
        return nextDay.format('ddd D MMM');
    }

}
