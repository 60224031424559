import { StylebrandingModel } from './../models/style-branding.model.';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import { CookieService } from '@core/cookie.service';
import { BaseService } from '@core/base.service';
import { Constants } from '@shared/services/constants';
import { ToasterService } from '@core/toaster.service';
import { AuthService } from '@core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {
  public countryCode;
  constructor(
    public cookieService: CookieService,
    public router: Router,
    public toaster: ToasterService,
    private http: HttpClient,
    private authService: AuthService) {
    super(router, toaster);
  }

  getBuilderFeatursUrl() {
    return this.builderFeaturesUrl;
  }

  getBuilderPaymentUrl(buildcardId: string) {
    const url = `${this.builderPaymentUrl}`;
    return url.replace('{buildCardId}', buildcardId);
  }
  

  getDataByBuildcardId(buildcardId: string, platform: string, platformType: string): Observable<any[]> {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.previewData}`;
    url = url.replace('{build_card_id}', buildcardId);
    url = url.replace('{platform}', platform);
    url = url.replace('{platformType}', platformType);
    if (this.authService.cutsotmPrototypeVersion !== null) {
      url = url.replace('{prototypeType}', Constants.prototypeType.customType);
    } else {
      url = url.replace('{prototypeType}', Constants.prototypeType.instantType);
    }
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
    return this.http.get<any[]>(`${url}`);
  }

  generatePrototypeVideo(buildcardId: string, platform: string, platformType: string ,features_ids: string[], blob: Blob, timestamp: any) {
    let url: string, formData = new FormData();
    url = `${this.baseUrl}${Constants.apiEndPoints.generatePrototypeVideo}`;
    url = url.replace('{build_card_id}', buildcardId);
    formData.append('device', platform);
    formData.append('platorm', platformType);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') formData.append('role_id', roleId);
    formData.append('feature_ids', JSON.stringify(features_ids));
    formData.append('short_video', blob, `blob_${timestamp}.webm`);
    return this.http.post<any>(url, formData);
  }

  launchScreen(buildcardId, heroId, device, platform, nowBuildCardId, version, oldLaunchScreenId): Observable<any> {
    let url = `${this.baseUrl}${'/' + Constants.apiEndPoints.launchScreen}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    const data = {
      device: device,
      platform: platform,
      build_card_feature_id: heroId,
      build_card_id: buildcardId,
      version: version,
      old_build_card_feature_id: oldLaunchScreenId
    };
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') data['role_id'] = roleId;
    return this.http.put<any>(url, data);
  }

  updateHotspots(hotspotsData: any) {
    const url = `${this.baseUrl}${Constants.apiEndPoints.updateHotspots}`;
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') hotspotsData['role_id'] = roleId;
    return this.http.put(url, hotspotsData);
  }

  getSideKickData(frameId, buildCardHeroImageId): Observable<any[]> {
    let url = `${this.baseUrl}${Constants.apiEndPoints.sideKickData}`;
    url = url.replace('{frame_id}', frameId);
    url = url.replace('{build_card_hero_image_id}', buildCardHeroImageId);
    return this.http.get<any[]>(`${url}`);
  }

  updateSideKicks(hotspotsData: any, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateSideKick}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') hotspotsData['role_id'] = roleId;
    return this.http.put(url, hotspotsData);
  }

  sendMailSMS(shareDetails: any, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.sendMailSMS}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.post(url, shareDetails);
  }

  deleteScreen(featureId, platform, platformType, nowBuilCardId, deleteScreenOrFeature) {
    let url = '';
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (deleteScreenOrFeature === 'delFeature') {
      url = `${this.baseUrl}${Constants.apiEndPoints.deleteScreen}`;
      url = url.replace('{feature_id}', featureId);
      url = url + `?build_card_id=${nowBuilCardId}&device=${platform}&platform=${platformType}`;
      if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
      return this.http.delete(url);
    } else {
      url = `${this.baseUrl}${Constants.apiEndPoints.unlinkScreen}`;
      url = url.replace('{feature_id}', featureId);
      url = url + `?build_card_id=${nowBuilCardId}&device=${platform}&platform=${platformType}`;
      if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
      return this.http.put(url, {});
    }
  }

  updateScreen(updateScreenData: any, featureId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateScreen}`;
    url = url.replace('{feature_id}', featureId);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') updateScreenData.append('roleId', roleId);
    return this.http.put(url, updateScreenData);
  }

  getSuggestions(featureId, heroFeatureId, nowBuilCardId, platform) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.suggestions}`;
    url = url.replace('{feature_id}', featureId);
    url = url.replace('{hero_feature_id}', heroFeatureId);
    url = url.replace('{build_card_id}', nowBuilCardId);
    url = url.replace('{platform}', platform);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
    return this.http.get<any[]>(`${url}`);
  }

  updateIndustry(updateIndustryData: any, buildcardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.setIndustryType}`;
    url = url.replace('{build_card_id}', buildcardId);
    return this.http.put(url, updateIndustryData);
  }

  uploadImageFeature(formData) {
    const url = `${this.baseUrl}${Constants.apiEndPoints.uploadImageFeature}`;
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') formData.append('roleId', roleId);
    return this.http.post(url, formData);
  }

  getIconsList(nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getIconsList}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.get<any[]>(`${url}`);
  }
  uploadIcons(formData, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getIconsList}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.post(url, formData);
  }
  saveIconHotspot(hotspotArrObj, featureId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.saveIconHotspot}`;
    url = url.replace('{feature_id}', featureId);
    return this.http.post(url, hotspotArrObj);
  }

  saveCustomSidekick(hotspotArrObj, frameId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.addCustomSidekick}`;
    url = url.replace('{feature_id}', frameId);
    return this.http.post(url, hotspotArrObj);
  }

  addCustomHotspot(hotspotArrObj, frameId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.addCustomHotspot}`;
    url = url.replace('{feature_id}', frameId);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') hotspotArrObj['roleId'] = roleId;
    return this.http.post(url, hotspotArrObj);
  }
  getConfigData(): Observable<any[]> {
    const url = `${this.baseUrl}${Constants.apiEndPoints.configurations}`;
    return this.http.get<any[]>(`${url}`);
  }
  statusCheck(nowBuildId, featureSetUniqueCode, platform) {
    featureSetUniqueCode = {
      feature_set_uniq_code: featureSetUniqueCode
    };
    let url = `${this.baseUrl}${Constants.apiEndPoints.checkStatus}`;
    url = url.replace('{now_build_id}', nowBuildId);
    url = url.replace('{platform}', platform);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;;

    return this.http.post(url, featureSetUniqueCode);
  }
  setPassword(nowBuildId, data) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.setPassword}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, data);
  }

  checkPassword(nowBuildId, data) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.checkPassword}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.post(url, data);
  }

  getPassword(nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getPassword}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.get(url);
  }
  // actionTypeList(nowBuildId) {
  //   let url = `${this.baseUrl}${Constants.apiEndPoints.industryTypeList}`;
  //   url = url.replace('{now_build_id}', nowBuildId);
  //   return this.http.get(url);
  // }
  updateProjectName(updatedName, nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateProjectName}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, updatedName);
  }
  updateFeatureName(data, featureId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateScreenName}`;
    url = url.replace('{feature_id}', featureId);
    return this.http.put(url, data);
  }
  setCPEPrototype(data, nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.setCPEPrototype}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, data);
  }
  customPrototypeAmount(nowBuildId) {
    let url = `${this.studioBaseUrl}${Constants.apiEndPoints.customPrototype}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.get(url);
  }
  syncData(data, nowBuildId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.syncData}`;
    url = url.replace('{now_build_id}', nowBuildId);
    return this.http.put(url, data);
  }
  approveRejectVersion(nowBuildCardId, data) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.approveFeatureVersion}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    return this.http.put(url, { build_card_approval_details: data });
  }

  postRejectionComment(trackerProjectId, data) {
    this.authService.isTrackerApi = true;
    let url;
    url = `${this.trackerBaseUrl}${Constants.apiEndPoints.trackerAPIPostRejectComment}`;
    url = url.replace('{builder_traker_project_id}', trackerProjectId);
    url += url.includes('?') ? '&notoast=1' : '?notoast=1';
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') data['role_id'] = roleId;
    return this.http.post(url,  data );
  }

  getBranding(buildcardId: string): Observable<StylebrandingModel> {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.branding}`;
    url = url.replace('{build_card_id}', buildcardId);
    return this.http.get<any>(`${url}`);
  }
  dummyLogo(data, buildcardId: string, brandingId: string): Observable<any> {
    let endPoint = Constants.apiEndPoints.updateBranding.replace('{build_card_id}', buildcardId);
    endPoint = `${this.baseUrl}${endPoint.replace('{now_branding_id}', brandingId)}`;
    return this.http.put(endPoint, data);
  }
  delete(attachment_id: string, buildcardId: string) {
    let endPoint = Constants.apiEndPoints.deleteBranding.replace('{build_card_id}', buildcardId);
    endPoint = `${this.baseUrl}${endPoint.replace('{delete_attachment_id}', attachment_id)}`;
    return this.http.delete(endPoint);
  }

  updateBranding(logo, font, color, icon, illustration, other, buildcardId: string, brandingId: string): Observable<any> {
    let endPoint = Constants.apiEndPoints.updateBranding.replace('{build_card_id}', buildcardId);
    endPoint = `${this.baseUrl}${endPoint.replace('{now_branding_id}', brandingId)}`;

    const updateLogo = this.http.put(endPoint, logo);
    const updateFont = this.http.put(endPoint, font);
    const updateColor = this.http.put(endPoint, color);
    const updateIcon = this.http.put(endPoint, icon);
    const updateIllustration = this.http.put(endPoint, illustration);
    const updateOther = this.http.put(endPoint, other);
    return forkJoin([updateLogo, updateFont, updateColor, updateIcon, updateIllustration, updateOther]);
  }
  getDashboardFeatureLists(buildcardId: string, platform: string, platformType: string) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.dashboardFeatureList}`;
    url = url.replace('{build_card_id}', buildcardId);
    url = url.replace('{platform}', platform);
    url = url.replace('{platformType}', platformType);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
    return this.http.get<any>(`${url}`);
  }
  addFeatures(nowBuildCardId, data) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.addFeatures}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') data['role_id'] = roleId;
    return this.http.post(url, data);
  }
  deleteFeatures(nowBuildCardId, data) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.deleteFeatures}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    url = url.replace('{feature_ids}', data);
    return this.http.put(url, data);
  }

  uploadLogo(formData, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.logoApi}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') formData.append('roleId', roleId);
    return this.http.put(url, formData);
  }

  getDataFromIsApi(nowBuildCardId, data) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.getDataFromIsApi}`;
    url = url.replace('{now_build_card_id}', nowBuildCardId);
    return this.http.post(url, data);
  }

  updateCarouselData(nowBuildCardId, data) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.updateCarouselData}`;
    url = url.replace('{now_build_card_id}', nowBuildCardId);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') data.append('roleId', roleId);
    return this.http.put(url, data);
  }

  getScreenComments(nowBuildcardId, payload) {
    let url;
    url = `${this.baseUrl}${Constants.apiEndPoints.getBCComments}`;
    url = url.replace('{now_build_card_id}', nowBuildcardId);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') payload['roleId'] = roleId;
    return this.http.post(url, payload);
  }

  addComment(payload) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.addComment}`;
    url += url.includes('?') ? '&notoast=1' : '?notoast=1';
    return this.http.post(url, payload);
  }

  updateComment(commentId, payload) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateComment}`;
    url = url.replace('{comment_id}', commentId);
    return this.http.put(url, payload);
  }

  showComment(commentId, payload) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.showComment}`;
    url = url.replace('{comment_id}', commentId);
    return this.http.post(url, payload);
  }

  deleteComment(commentId, payload) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.deleteComment}`;
    url = url.replace('{comment_id}', commentId);
    return this.http.post(url, payload);
  }

  getDashboardCommentMarks(nowBuildCardId, platform, platformType, studio_build_card_id?) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getCommentMarks}`;
    url = url.replace('{build_card_id}', nowBuildCardId);
    if(studio_build_card_id){
      url = url+`&studio_build_card_id=${studio_build_card_id}`;
    }
    url = url.replace('{platform}', platform);
    url = url.replace('{platformType}', platformType);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
    return this.http.get(url);
  }

  getSubFeatureCommentMarks(trackerId, stidioFeatureId) {
    this.authService.isTrackerApi = true;
    let url;
    url = `${this.trackerBaseUrl}${Constants.apiEndPoints.showSubFeaturesComments}`;
    url = url.replace('{projectId}', trackerId);
    url = url.replace('{builderFeatureId}', stidioFeatureId);
    // url = url.replace('{pageNo}', pageNo);
    // url = url.replace('{perPage}', perPage);
    // url = url.replace('{userType}', userType);
    return this.http.get<any>(`${url}`);
  }

  customCommentsPosition(projectId) {
    this.authService.isTrackerApi = true;
    let url;
    url = `${this.trackerBaseUrl}${Constants.apiEndPoints.customCommentsPosition}`;
    url = url.replace('{projectId}', projectId);
    return this.http.get<any>(`${url}`);
  }
  showcustomFeaturesComments(projectId, builder_feature_id, pageNo, perPage, story_id, platform) {
    this.authService.isTrackerApi = true;
    let url;
    if(story_id) {
      url = `${this.trackerBaseUrl}${Constants.apiEndPoints.showcustomSubFeaturesComments}`;
      url = url.replace('{story_id}', story_id);
    } else {
      url = `${this.trackerBaseUrl}${Constants.apiEndPoints.showcustomFeaturesComments}`;
    }
    url = url.replace('{builderFeatureId}', builder_feature_id);
    url = url.replace('{projectId}', projectId);
    url = url.replace('{pageNo}', pageNo);
    url = url.replace('{perPage}', perPage);
    url = `${url}&platform_name=${platform}`;
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
    // url = url.replace('{userType}', userType);
    return this.http.get<any>(`${url}`);
  }

  trackerUpdateCustomComment(projectId, commentId, data) {
    this.authService.isTrackerApi = true;
    let url;
    url = `${this.trackerBaseUrl}${Constants.apiEndPoints.trackerUpdateCustomComment}`;
    url = url.replace('{projectId}', projectId);
    url = url.replace('{commentId}', commentId);
    return this.http.put<any>(`${url}`, data);
  }

  trackerDeleteCustomComment(projectId, commentId) {
    this.authService.isTrackerApi = true;
    let url;
    url = `${this.trackerBaseUrl}${Constants.apiEndPoints.trackerDeleteCustomComment}`;
    url = url.replace('{projectId}', projectId);
    url = url.replace('{commentId}', commentId);
    return this.http.delete<any>(`${url}`);
  }

  getTrackerCommentById(trackerProjectId, commentId) {
    this.authService.isTrackerApi = true;
    let url;
    url = `${this.trackerBaseUrl}${Constants.apiEndPoints.trackerApiGetCommentById}`;
    url = url.replace('{builder_traker_project_id}', trackerProjectId);
    url = url.replace('{commentId}', commentId);
    return this.http.get<any>(`${url}`);
  }


  pollBuildCardStatus(inProgressBuildCardId: any) {
    let apiUrl = this.studioBaseUrl + '/build_cards/{id}/poll_status';
    apiUrl = apiUrl.replace('{id}', inProgressBuildCardId);
    return this.http.get(apiUrl);   /*  , this.appDataService.getRequestOptionArgs()   */
  }

  scheduleCallPolling(inProgressBuildCardId: any) {
    this.authService.isDashboardApi = true;
    let apiUrl = this.pmDashboardUrl + 'clients/projects/project_polling?build_card_id={id}'
    apiUrl = apiUrl.replace('{id}', inProgressBuildCardId);
    return this.http.get(apiUrl);
  }
  createAndSyncBuildCardToNow(appName) {
    if (appName.indexOf(' ') !== -1) {
      appName = appName.split(' ').join('-').toLowerCase();
    }
    const API = this.studioBaseUrl + '/templates/' + appName + '/create_build_card';
    return this.http.post(API, null);    /*    , this.appDataService.getRequestOptionArgs()    */
  }

  login_signup(userdetails, option) {
    let API;
    let data = {};
    if (option === 'login') {
      API = this.studioBaseUrl + Constants.apiEndPoints.user_signin;
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data[key] = value;
          }
        }
      }
    } else if (option === 'signup') {
      API = this.studioBaseUrl + Constants.apiEndPoints.user_signup;
      data = {user: {}};
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data['user'][key] = (key === 'phone_number') ? value.replace('+' + this.countryCode, '+' + this.countryCode + '-') : value;
          }
        }
      }
    }
    return this.http.post(API, data);
  }

  updateUserAddress(address) {
    const uri = `${this.studioBaseUrl}/users`;
    const obj = {
      user: {
        address_attributes: {
          first_name: address.firstname,
          last_name: address.lastname,
          address: address.address,
          street: address.street,
          apartment: address.apartment,
          state: address.state,
          city: address.city,
          pincode: address.pincode,
          phone: address.contact, //address.contact.replace(this.authService.countryNameCode, this.authService.countryNameCode + '-'),
          country: address.country,
          gst_number: address.gstNumber,
          company_name: address.companyName ? address.companyName : ''
        },

      }
    };
    return this.http.put(uri, obj);    /*   , this.appDataService.getRequestOptionArgs()    */
  }

  updateNDA(inProgressBuildCardId) {
    let API = this.studioBaseUrl + Constants.apiEndPoints.build_card;
    if (inProgressBuildCardId) {
      API += '/' + inProgressBuildCardId;
    }
    if (inProgressBuildCardId) {
      return this.http.put(API, {
        'build_card': {
          'meta_data': {
            'studio_store_nda_signed': true
          }
        }
      });    /*   , this.appDataService.getRequestOptionArgs()   */
    }
  }

  sendPaymentDetailsAndPoll(paymentData: any) {
    const apiUrl = this.paymentUrl + Constants.apiEndPoints.get_payment_url;
    return this.http.post(apiUrl, paymentData); /*  , this.appDataService.getRequestOptionArgs()    */
  }

  addScheduleDate(build_care_id, data) {
    const API = this.studioBaseUrl + '/build_cards/' + build_care_id + '/set_scheduled_date';
    const formData = new FormData();
    formData.append('[scheduled_date]', data.date);
    formData.append('[scheduled_call_start]', data.slot_start);
    formData.append('[scheduled_call_end]', data.slot_end);

    return this.http.post(API, formData);   /*   , this.appDataService.getRequestOptionArgs()   */
  }

  fetchSingleBuildCardData(cardId) {
    const API = this.studioBaseUrl + '/build_cards/' + cardId;
    return this.http.get(API);
  }
  getPreview(nowBuildCardId, buildCardHeroImageId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getFeaturePreview}`;
    url = url.replace('{build_card_hero_image_id}', buildCardHeroImageId);
    url = url.replace('{now_build_card_id}', nowBuildCardId);
    return this.http.get(url);
  }

  getLocaledPrice(price) {
    if (price != null) {
      price = String(price);
    }
    if (price != null) {
      if (price.indexOf(",") !== -1) {
        price = price.split(",").join("");
      }
      let priceSliced = [];
      let decimalPart = "00";
      if (price.indexOf(".") != -1) {
        priceSliced = price.split(".");
        price = priceSliced[0];
        decimalPart = priceSliced[1];
      }
      const formatter = new Intl.NumberFormat('en-US');
      let formatedPrice = formatter.format(price);
      if (formatedPrice.indexOf(".") == -1) {
        formatedPrice =
          formatedPrice + '.' + decimalPart;
      }
      return formatedPrice;
    } else {
      return "0.00";
    }
  }
  checkEmailBeforeSigninSignup(email) {
    let url = `${this.studioBaseUrl}${Constants.apiEndPoints.checkEmailBeforeSigninSignup}`;
    url = url.replace('{email}', email);
    return this.http.get(url);
  }
  updateCurrency(currencyObj) {
    const url = `${this.studioBaseUrl}${Constants.apiEndPoints.user_signup}`;
    return this.http.put(url, currencyObj);
  }
  forgotPassword(data) {
    const API = `${this.studioBaseUrl}${Constants.apiEndPoints.forgotPassword}`;
    return this.http.post(API, data);
  }
  resetPassword(data) {
    const API = `${this.studioBaseUrl}${Constants.apiEndPoints.resetPassword}`;
    return this.http.post(API, data);
  }

  updateReviewScreenFlag(payload, nowBuilCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateReviewScreenFlagApi}`;
    url = url.replace('{now_build_card_id}', nowBuilCardId);
    return this.http.put(url, payload);
  }

  getFeatureVersions(featureId, platform, platformType) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getFeatureVersions}`;
    url = url.replace('{featureId}', featureId);
    url = url.replace('{platform}', platform);
    url = url.replace('{platformType}', platformType);
    const roleId = this.cookieService.getLocalStorage('roleId');
    if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
    return this.http.get(url);
  }

  getFtueData(buildCardId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getFtueDataApi}`;
    url = url.replace('{buildCardId}', buildCardId);
    return this.http.get(url);
  }

  postFtueData(buildCardId, payload) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getFtueDataApi}`;
    url = url.replace('{buildCardId}', buildCardId);
    return this.http.post(url, payload);
  }
  //change for multiple

  postRejectionCommentMultiple(trackerProjectId, data) {
    this.authService.isTrackerApi = true;
    let url;
    url = `${this.trackerBaseUrl}${Constants.apiEndPoints.trackerAPIPostRejectComment}`;
    url = url.replace('{builder_traker_project_id}', trackerProjectId);
    let commentApiArray =[];
    data.forEach(element => {
     commentApiArray.push(this.http.post(url,element));
    }); 
   return forkJoin(commentApiArray); 
  }

  putUndoRedo(val, buildCardId, payload) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.undoRedoApi}`;
    url = url.replace('{buildCardId}', buildCardId);
    url = url.replace('{undoRedo}', val);
    return this.http.put(url, payload);
  }

  postFeedBack(payload){
    let url = `${this.baseUrl}${Constants.apiEndPoints.postFeedback}`;
    return this.http.post(url, payload);
  }

  getPlatforms(studio_build_card_id){
    let url = `${this.baseUrl}${Constants.apiEndPoints.getPlatforms}`;
    url = url.replace('{studio_build_card_id}', studio_build_card_id);
    return this.http.get<any[]>(`${url}`);
   }

   getNotification(build_card_id, platform, platformType, email, page){
     let url = `${this.baseUrl}${Constants.apiEndPoints.getNotification}`;
     url = url.replace('{build_card_id}', build_card_id);
     url = url.replace('{device}', platform);
     url = url.replace('{platform}', platformType);
     url = url.replace('{email}', email);
     url = url.replace('{page}', page);
     const roleId = this.cookieService.getLocalStorage('roleId');
     if (roleId && roleId !== 'undefined') url = url + `&role_id=${roleId}`;
    return this.http.get(`${url}`);
   }

   markAsReadSingle(payload,notification_id){
    let url = `${this.baseUrl}${Constants.apiEndPoints.markAsReadSingle}`;
    url = url.replace('{notification_id}', notification_id);
    return this.http.put(`${url}`,payload);
   }

   markAllRead(payload){
    let url = `${this.baseUrl}${Constants.apiEndPoints.markAsReadAll}`;
    url = url.replace('{build_card_id}', payload.build_card_id);
    return this.http.put(`${url}`,payload);
   }

   saveWhiteBoard(buildCardId,platform,device,payload){
    let url = `${this.baseUrl}${Constants.apiEndPoints.saveBoard}`;
    url = url.replace('{buildCardId}', buildCardId);
    return this.http.post(url, payload);
   }

   getReleaseNotes(email) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.releaseNotes}`;
    url = url.replace('{email}', email);
    return this.http.get(url);
  }

  markReleaseRead(payload){
    let url = `${this.baseUrl}${Constants.apiEndPoints.releaseRead}`;
    url += url.includes('?') ? '&notoast=1' : '?notoast=1';
    return this.http.put(`${url}`,payload);
   }

  markMaintenanceRead(payload) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.maintenanceRead}`;
    url += url.includes('?') ? '&notoast=1' : '?notoast=1';
    return this.http.put(`${url}`, payload);
  }

  updateCustomName(updatedName, bCardFeatureId) {
    let url = `${this.baseUrl}${Constants.apiEndPoints.updateCustomImageName}`;
    url = url.replace('{build_card_feature_id}', bCardFeatureId);
    return this.http.put(url, updatedName);
  }
  
  getDetailsByZipCode(zipCode, countryCodeISO2): Observable<any> {
    return this.http.get(`https://api.worldpostallocations.com/pincode?postalcode=${zipCode}&countrycode=${countryCodeISO2}`);
  }

  getBuildCardRoles(build_card_id, prototype_type): Observable<any> {
    let url = `${this.baseUrl}${Constants.apiEndPoints.getBuildCardRoles}`;
    const mapObj = {
      '{build_card_id}': build_card_id,
      '{prototype_type}': prototype_type
    };
    url = url.replace(/(?:\{build_card_id\}|\{prototype_type\})/gi, matched => mapObj[matched]);
    return this.http.get(url);
  };
}
