export class CurrencyModel {
  id: string;
  name: string;
  code: string;
  exchangeRate: number;
  multiplier: number;
  symbol: string;
  countryCode: string;
  specingPrice: number;
  iconImageFileUrl: string;
  instantSpecPrice: number;
  tax: number;
  custom_prototype_price: number;
  post_upfront_price: number;
}
