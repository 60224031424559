export const environment = {
    production: false,
    envName: 'beta',
    API_URL: 'https://api-staging-beta-now.engineer.ai',
    STUDIO_API_URL: 'https://api-staging-builder.engineer.ai',
    STUDIO_URL: 'https://dev.engineer.ai',
    TRACKER_API_URL: 'https://staging.api.tracker.engineer.ai',
    TRACKER_SECRET_KEY: 'PXIdJKzxCXowjXPIRgLm',
    BUILDER_PAYMENT_URL: 'https://dev.engineer.ai/instant_prototype?build_card_id={buildCardId}',
    BUILDER_FEATURES_URL: 'https://dev.engineer.ai/features',
    USER_COOKIE: 'user_beta',
    GUIDLINE_TOOTIP_COOKIE: 'beta_guidline',
    ONBOARDING_COOKIE: 'beta_onboarding',
    VERSION0_TOOLTIP_COOKIE: 'beta_version0',
    enableSentry: false,
    PAYMENT_APP_TOKEN: '9ut4ECNZXKlh5oBKclp_MA',
    PAYMENT_URL : 'https://staging-payments.engineer.ai/',
    INTERCOM_APP_ID : 'ob3az46u',
    CALENDLY_URL : 'https://staging-scheduler.builder.ai/?call_type={type}&token=tpdpdxTvmdfGFyDpWTaK',
    PM_DASHBOARD_AUTH:'tpdpdxTvmdfGFyDpWTaK',
    PM_DASH_URL: 'https://api-staging-pmdashboard.engineer.ai/api/v1/',
    white_board_base_url: "https://www.whiteboard.team",
    whiteBoardClientId: "de407599b5e9da86506556a7a958184d",
    whiteBoardClientSecret: "5b636e6f0ab506cbbb36707c45f8e9de"
  };
  