<div class="overviewSection" *ngIf="!dataCommService.showViewUnlimitedVersion">
<h3>Tailor-made prototypes</h3>
<p>Access a top designer – who'll craft each screen of your prototype for your business.</p>
<ul>
    <li><em class="icon-ticknew"></em> Main feature screens (up to 25 screens).</li>
    <li><em class="icon-ticknew"></em> Make it unique: your logo, colours and images.</li>
    <li><em class="icon-ticknew"></em> Rapid turnaround time (less than 5 days).</li>
</ul>

    <div class="detailRow">
        <div class="detailBox">
          {{dataCommService.featureCount}} {{dataCommService.featureCount > 1 ? 'Features': 'Feature'}} at
            <strong>{{ dataCommService.tailorMadeSysmbol}}{{utilityService.getLocaledPrice((dataCommService.tailorMadePrice) | number : '1.2-2') }}</strong>
        </div>

        <div class="detailBox">
            Get it by <div class="infoBox"><div class="infoIcon">i</div> <span>Add your branding & book kickoff call now and get it by this date.</span></div>
            <strong>{{ getDeliveryDate('tailorMade') }}</strong>
        </div>
    </div>

<button type="button" class="buttonStyle" (click)="proceedNext('tailorMade')">Get started</button>

   <div class="viewUnlimitedVersion-wraper"> <span class="viewUnlimitedVersion" (click)="viewUnlimitedVersion()">View Unlimited Version</span></div>
</div>


<div class="overviewSection"  [@fadeInOut] *ngIf="dataCommService.showViewUnlimitedVersion">
    <h3>Professional prototypes</h3>
    <p>Access top designers - who’ll craft each screen of your prototype for your business</p>
    <ul>
        <li><em class="icon-ticknew"></em> Discuss your dream prototype with us.</li>
        <li><em class="icon-ticknew"></em> Get every screen designed from scratch.</li>
        <li><em class="icon-ticknew"></em> Unlimited custom screens.</li>
    </ul>

    <div class="detailRow">
        <div class="detailBox">
            {{dataCommService.featureCount}} {{dataCommService.featureCount > 1 ? 'Features': 'Feature'}} at
            <strong>{{ dataCommService.customPrototypeSysmbol}}{{utilityService.getLocaledPrice((dataCommService.customPrototypePrice) | number : '1.2-2') }}</strong>
        </div>

        <div class="detailBox">
            Get it by <div class="infoBox"><div class="infoIcon">i</div> <span>Add your branding & book kickoff call now and get it by this date.</span></div>
            <strong>{{ getDeliveryDate('professional') }}</strong>
        </div>
    </div>

    <button type="button" class="buttonStyle" (click)="proceedNext('professional')">Get started</button>
</div>
