import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import * as Sentry from '@sentry/browser';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { Page404Component } from './page404/page404.component';
import { EmptyboardComponent } from './emptyboard/emptyboard.component';
import { FileSaverModule } from 'ngx-filesaver';
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2Segment} from 'angulartics2/segment';
import {environment} from '@env/environment';
import { WipComponent } from './wip/wip.component';
import {AuthInterceptor} from '@core/auth-interceptor';
import {TermsComponent} from './terms/terms.component';
import { IntercomModule} from "ng-intercom";
import { GlobalErrorHandler } from 'src/GlobalErrorHandler';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.enableSentry) {
      const eventId = Sentry.captureException(error.originalError || error);
      //   Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
    EmptyboardComponent,
    WipComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    SharedModule,
    AppRoutingModule,
    SocialLoginModule,
    FileSaverModule,
    IntercomModule.forRoot({
      appId: environment.INTERCOM_APP_ID, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    Angulartics2Module.forRoot([Angulartics2Segment])
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },   
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('399522936347-01vsdt9ffp5kvf4bm1c05g0ut0m7homi.apps.googleusercontent.com')
        },
        {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1889590484589531')
        }
        ]
      } as SocialAuthServiceConfig,
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    if (window.location.href.indexOf('localhost') === -1) {
      Sentry.init({
        dsn: 'https://5ede061e05a74106a5fe534cacb71424@sentry.engineer.ai/11'
      });
    }
  }
}
