<!DOCTYPE>
<html>
<head>
  <title>Under Construction</title>
</head>
<body>
<div class="wip-container-wrap">
  <div class="wip-container">
    <div class="logo"><img src="../../assets/images/buildernow-logo.svg"></div>
    <div class="wip-img">
      <img src="../../assets/images/wip.jpg">
    </div>
    <div class="wip-txt"><p>Coming soon… this app will get instant prototype shortly.</p></div>
  </div>
</div>
</body>
</html>
