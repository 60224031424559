import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {DataService} from '../../../../../main/shared/services/data.service';
import {BaseService} from '@core/base.service';
import {AuthService as Auth} from '@core/auth.service';
import {Router} from '@angular/router';
import {Constants} from '@shared/services/constants';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  invalidPassword = false;
  queryParams = {};
  resetPasswordState = 1;
  invalidPasswordErrMeggase: string;
  constructor(private fb: FormBuilder,
              public dataCommService: DataCommService,
              private dataService: DataService,
              public baseurl: BaseService,
              public authService: Auth,
              private router: Router) { }

  ngOnInit() {
    this.initializeLoginForm();
  }
  initializeLoginForm() {
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.required, Validators.minLength]
    });
  }
  get password() {
    return this.resetPasswordForm.get('password');
  }
  checkIfPasswordValid(): boolean {
    return this.password && !!this.password.errors && (this.password.pristine || this.password.invalid);
  }
  resetPassword() {
    if (this.checkIfPasswordValid()) {
      this.invalidPassword = true;
      return;
    } else {
      this.invalidPassword = false;
    }
    this.queryParams = this.baseurl.setQueryparams();
    if (this.queryParams['token']) {
      const data = {
        password: this.password.value,
        password_confirmation: this.password.value,
        reset_password_token: this.queryParams['token']
      };
      this.dataService.resetPassword(data).subscribe(res => {
        this.resetPasswordState = 2;
      }, (err) => {
        this.invalidPassword = true;
        this.invalidPasswordErrMeggase = 'token is missing';
      });
    }
  }

  goToLogin() {
    this.dataCommService.selectedSectionIndex = 0;
    if (this.queryParams['?builder_id']) {
      this.dataCommService.touchPoint = Constants.touchPoints.previewHeader;
      this.dataCommService.getTouchPointHeadings('checkEmail');
      if (this.queryParams['version']) {
        this.router.navigateByUrl('/' + this.queryParams['?builder_id'] + '/' + this.queryParams['version'] + '/preview');
      } else {
        this.router.navigateByUrl('/' + this.queryParams['?builder_id'] + '/preview');
      }
    } else {
      this.dataCommService.touchPoint = Constants.touchPoints.campaignLink;
      this.dataCommService.getTouchPointHeadings('checkEmail');
      this.router.navigateByUrl('/?red=now&application=' + this.queryParams['?application']);
    }
  }

}
