<h2 class="heading">
    {{dataCommService.touchPointHeading}}
    <span *ngIf="dataCommService.touchPointSubHeading!=='Changed'">{{dataCommService.touchPointSubHeading}}</span>
    <span *ngIf="dataCommService.touchPointSubHeading==='Changed'" style="display: inline;">{{dataCommService.touchPointSubHeading}}</span>
</h2>

<form class="forgot-container" [formGroup]="forgotPasswordForm" *ngIf="forgotPasswordState === 1">
    <ul>
        <li>
            <label>Email*</label>
            <input type="text" placeholder="Enter your email" formControlName="email" required (keyup)="clearMessage()">
            <div class="errorMsg" *ngIf="(invalidEmail && !invalidEmailErrMeggase) || (email.touched && checkIfEmailValid())">Please enter valid email</div>
            <div class="errorMsg" *ngIf="invalidEmail && invalidEmailErrMeggase">{{ invalidEmailErrMeggase }}</div>
        </li>
    </ul>
    <button type="button" class="buttonStyle filGreenBtn" (click)="forgotPassword()">Reset password</button>
    <h5 class="link" (click)="goToSignin()">Back to <span class="highlights">Sign In</span></h5>
</form>
<div class="forgot-success" *ngIf="forgotPasswordState === 2">
    <div class="success-wrap">
        <span class="success-icon icon-success-tick"></span>
        <h3 class="title">Password reset link sent.</h3>
        <p class="link">We've sent you a verification link to your email, if you have not received&nbsp;<a class="highlights" (click)="forgotPassword()">send again</a></p>
    </div>
    <button type="button" class="buttonStyle" (click)="goToSignin()">Back to login</button>
</div>
