import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {AuthService} from '@core/auth.service';
import { Constants } from '@shared/services/constants';
import { CookieService } from '@core/cookie.service';

@Component({
  selector: 'app-rental-build-card',
  templateUrl: './rental-build-card.component.html',
  styleUrls: ['./rental-build-card.component.scss']
})
export class RentalBuildCardComponent implements OnInit {

  @Input() appDetails;
  @Input() isSrcLogout;

  constructor(
    public dataCommService: DataCommService, private router: Router,
    public authService: AuthService,
    private cookieService: CookieService) {
  }

  ngOnInit(): void {
    if (this.isSrcLogout) {
      this.dataCommService.touchPoint = Constants.touchPoints.previewHeader;
      this.dataCommService.getTouchPointHeadings('checkEmail');
    }
  }

  closeModal() {
    if (this.dataCommService.isRentalCardPaid) {
      this.cookieService.setLocalStorage("successfullPayment", "done");
      // this.router.navigate(['/' + this.dataCommService.buildcardId + '/v1' + '/preview/' + this.dataCommService.platform]);
      this.dataCommService.closeAllOnboarding();
      window.location.href = '/' + this.dataCommService.buildcardId + '/v1' + '/preview/' + this.dataCommService.platform;
    } else {
      this.dataCommService.startPollingForPayment(true);
     if (!this.router.url.includes('preview')) {
        window.location.reload();
      }
    }
    this.dataCommService.showHidePaymentFlow(false);
    this.dataCommService.showCallBookedSuccess = false;
    this.dataCommService.paymentUrl = '';
    if (this.dataCommService.windowToCLose) {
      this.dataCommService.windowToCLose.close();
      this.dataCommService.windowToCLose = null;
    }
    if (this.dataCommService.screenTobeAppear === 'login') {
      const queryParams = this.dataCommService.setQueryparams();
      if (queryParams && queryParams['?red'] === 'now') {
        const segmentTrackerData = {
          screen_name: 'SignIn_popup',
          source: 'Builder Now campaign',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      } else if (this.dataCommService.isUserClickedOnPreviewToPutComment) {
        const segmentTrackerData = {
          screen_name: 'SignIn_popup',
          source: 'Builder Now Comments',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      } else {
        const segmentTrackerData = {
          screen_name: 'SignIn_popup',
          source: 'Builder Now Preview',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      }
    } else if (this.dataCommService.screenTobeAppear === 'signup') {
      const queryParams = this.dataCommService.setQueryparams();
      if (queryParams && queryParams['?red'] === 'now') {
        const segmentTrackerData = {
          screen_name: 'Signup_popup',
          source: 'Builder Now campaign',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      } else if (this.dataCommService.isUserClickedOnPreviewToPutComment) {
        const segmentTrackerData = {
          screen_name: 'Signup_popup',
          source: 'Builder Now Comments',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      } else {
        const segmentTrackerData = {
          screen_name: 'Signup_popup',
          source: 'Builder Now Preview',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      }
    } else if (this.dataCommService.selectedSectionIndex === 7) {
      const queryParams = this.dataCommService.setQueryparams();
      if (queryParams && queryParams['?red'] === 'now') {
        const segmentTrackerData = {
          screen_name: 'forgot_password_popup',
          source: 'Builder Now campaign',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      } else if (this.dataCommService.isUserClickedOnPreviewToPutComment) {
        const segmentTrackerData = {
          screen_name: 'forgot_password_popup',
          source: 'Builder Now Comments',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      } else {
        const segmentTrackerData = {
          screen_name: 'forgot_password_popup',
          source: 'Builder Now Preview',
        };
        this.dataCommService.trackEvent('Signup/login window Close', segmentTrackerData);
      }
    }
  }
}

