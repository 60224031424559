import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {DataService} from '../../../../../main/shared/services/data.service';
import {BaseService} from '@core/base.service';
import {AuthService as Auth} from '@core/auth.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  invalidEmailErrMeggase: string;
  invalidEmail = false;
  forgotPasswordState = 1;
  queryParams = {};
  constructor(private fb: FormBuilder,
              public dataCommService: DataCommService,
              private dataService: DataService,
              public baseurl: BaseService,
              public authService: Auth) { }

  ngOnInit() {
    this.initializeLoginForm();
  }
  initializeLoginForm() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }
  clearMessage() {
    this.invalidEmailErrMeggase = '';
  }
  checkIfEmailValid(): boolean {
    return !this.dataCommService.validateEmail(this.email.value);
  }

  forgotPassword() {
    if (this.checkIfEmailValid()) {
      this.invalidEmail = true;
      return;
    } else {
      this.invalidEmail = false;
    }
    this.queryParams = this.baseurl.setQueryparams();
    let redirectUrl;
    if (this.queryParams['application']) {
      redirectUrl = window.location.origin + '/reset-password?application=' + this.queryParams['application'].trim().replace(/\s/g, '%20');
    } else {
      if (this.authService.cutsotmPrototypeVersion) {
        redirectUrl = window.location.origin + '/reset-password?builder_id=' + this.dataCommService.buildcardId + '&version=' + this.authService.cutsotmPrototypeVersion;
      } else {
        redirectUrl = window.location.origin + '/reset-password?builder_id=' + this.dataCommService.buildcardId;
      }
    }
    const data = {
      email: this.email.value,
      url: 'http://localhost:8080/reset-password',
      redirect_url: redirectUrl
    };
    this.dataService.forgotPassword(data).subscribe(res => {
      this.forgotPasswordState = 2;
      this.dataCommService.getTouchPointHeadings('resetPassword2');
    }, (err) => {
      this.invalidEmail = true;
      this.invalidEmailErrMeggase = 'There is no account exists with this email address';
    });
  }
  goToSignin() {
    this.dataCommService.selectedSectionIndex = 0;
    this.dataCommService.getTouchPointHeadings('checkEmail');
  }

}
