import {Component} from '@angular/core';
import {DataService} from './main/shared/services/data.service';
import {CookieService} from '@core/cookie.service';
import {WindowRefService} from '@core/window-ref.service';
import {AuthService} from '@core/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public dataService: DataService,
              public cookieService: CookieService,
              private windowRef: WindowRefService,
              public authService: AuthService
  ) {
    this.authService.setUserProfile();
  }

}
