<div class="tnc-page">
    <perfect-scrollbar>
    <div class="header">
        <img class="logo" src="../../../../assets/images/buildernow-logo.svg">
    </div>
    <div class="tnc-container">
        <div class="wrap">
            <h1 class="title">Terms & Conditions</h1>
            <p class="content">Builder.ai (B.ai) site is developed and owned by Engineer.ai Global Limited, a technology and cloud services company, hereinafter referred to as the ‘Company’ with its registered office at 15 Westferry Circus, Canary Wharf, London, England, E14 4HD. The B.ai Website terms & conditions (“T&C”) apply to your access and use of www.builder.ai (the “Site”), including all content, data, images, pricing, reports, software, voice, text and video made available through any portion of the Site (collectively, the “Content”). Content includes all such elements as whole, as well as individual items and portions thereof.</p>
            <p class="content">B.ai permits you (“User” or “you” or “your”) referred to as you, your, user or the ‘Client’ to access and use the Site and Content, subject to these T&C. By accessing or using any portion of the Site, you acknowledge that you have read, understood, and agree to be bound by these T&C. If you are entering into an agreement on these T&C on behalf of a company or other legal entity (“User Entity”), you must have the legal authority to contractually bind such User Entity to these T&C, in which case the terms “you” or “your” or “User”, the “Client” will refer to such User Entity. If you lack such legal authority to contractually bind or you do not agree with these T&C, you must refrain from accessing or using the site or content.</p>
            <p class="content">You may use B.ai to specify and assign projects to the Company. A Project Assignment will become binding when both parties have signed a separate agreement / contract referred to as the “Contract” for the project and once an the Contract is signed, Company will be obligated to provide the services and to deliver the materials and deliverables as specified in each Project Assignment. The terms of the Contract will govern all Project Assignments and services undertaken by Company for Client.</p>
        </div>
        <div class="wrap">
            <h3 class="sub-title">Terms & Conditions Updates</h3>
            <p class="content">Company reserves the right, at its sole discretion, to change or modify portions of the T&C at any time. The Company will post the changes on the Site and will indicate the date these terms were last revised. It is your responsibility to check the T&C periodically for changes. Your continued use of the Site and Content after the date, any such changes become effective constitutes your acceptance of the new or revised T&C.</p>
        </div>
        <div class="wrap">
            <h3 class="sub-title">Ownership and Restrictions</h3>
            <p class="content">Company reserves the right, at its sole discretion, to change or modify portions of the T&C at any time. The Company will post the changes on the Site and will indicate the date these terms were last revised. It is your responsibility to check the T&C periodically for changes. Your continued use of the Site and Content after the date, any such changes become effective constitutes your acceptance of the new or revised T&C.</p>
            <p class="content">1, sublicense, resell, rent, lease, transfer, assign, time share or otherwise commercially exploit or make the Site and any Content available to any third party,</p>
            <p class="content">2, use the Site and Content in any unlawful manner (including without limitation in violation of any data, privacy or import / export control laws) or in any manner that interferes with or disrupts the integrity or performance of the Site and Content or their related components, including and not limited to</p>
            <p class="content">- Making any speculative, false, or fraudulent reservation or any reservation in anticipation of demand</p>
            <p class="content">- Violate the restrictions in any robot exclusion headers on this website or bypass or circumvent other measures employed to prevent or limit access to this website;</p>
        </div>
    </div>
    </perfect-scrollbar>
</div>
