<div class="thankyouArea" *ngIf="!dataCommService.showCalendar && (dataCommService.selectedSectionIndex === 4)">
  <div class="topPart">
    <div class="topIcon"><span><em class="icon-right"></em></span></div>
    <h3>Thank you!</h3>
    <p>This payment was successful, we'll send your receipt to the email on your account.</p>
    <h4 *ngIf="(getTransactionID() && (getTransactionID().length > 0))">Transaction Reference no: {{getTransactionID()}}</h4>
    <!--<h5 (click)="showHideOrderSummary(true)">View order details</h5>-->
    <div class="downloadArrow">
      <em class="icon-rightarrow"></em>
    </div>
  </div>

  <div class="bottomPart">
    <h3>What will our expert help you with?</h3>
    <ul>
      <li><em class="icon-right"></em> Run you through entire process.</li>
      <li><em class="icon-right"></em> Get everything designed the way you want it.</li>
      <li><em class="icon-right"></em> Answer all your questions.</li>
    </ul>
    <button type="button" class="buttonStyle" [disabled]="dataCommService.scheduleCallWaiting" (click)="scheduleExertCall()">Schedule an expert call</button>
  </div>
</div>

<div class="calenderArea" *ngIf="dataCommService.showCalendar && (dataCommService.selectedSectionIndex === 4)">
  <iframe frameborder="0" [src]="dataCommService.schedulerURL"></iframe>
  <!--<div class="calenderHolder">
    <input class="calenderInput" placeholder="Click to select a date"
           angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
           [(ngModel)]="model" [options]="myDpOptions" type="hidden"
           #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)" (calendarToggle)="onCalendarToggle($event)"
           [ngClass]="{'hide' : !isScheduleCall}"/>
  </div>

  <div class="timeSlot">
    <h3>Select time slot</h3>
    <ul>
      <li><select [ngModel]="startTimeSlot" (ngModelChange)="startTimeSlotChanged($event)" name="startTime">
        <option *ngFor="let slot of slotsArray" [value]="slot"
        >{{slot}}</option>
      </select></li>
      <li><select [ngModel]="endTimeSlot" (ngModelChange)="endTimeSlotChanged($event)" name="endTime">
        <option *ngFor="let slot of endSlotsArray" [value]="slot"
        >{{slot}}</option>
      </select></li>
    </ul>

  </div>-->


</div>

<!--<div class="thankyouArea" *ngIf="!dataCommService.showCalendar && (dataCommService.selectedSectionIndex === 5)">
  <div class="topPart">
    <div class="topIcon"><span><em class="icon-right"></em></span></div>
    <h3>Your call is booked</h3>
    <p>Your call is scheduled on <span>{{dateString}}</span> at <span>{{this.startTimeSlot}}
      -{{this.endTimeSlot}}</span>, we'll send the details on your email address.</p>
    <div class="downloadArrow">
      <em class="icon-rightarrow"></em>
    </div>
  </div>

  <div class="bottomPart">
    <h3>Why do you need to add your branding?</h3>
    <ul>
      <li><em class="icon-right"></em> We need your logo, colours and fonts to start.</li>
      <li><em class="icon-right"></em> Add the illustration and icon styles you like too.</li>
      <li><em class="icon-right"></em> It’s more likely you’ll love our designs, first time.</li>
    </ul>
    <button type="button" class="buttonStyle" (click)="addBranding()">Add branding</button>
  </div>
    <a class="skip-link" (click)="goToFlowchart()">I’ll do it later</a>
</div>-->
