<div class="contractSection">
  <ul [ngClass]="{'disable-billing-form': (authService.getLoggedInUser().address_disabled)}">
    <li>
      <label>Company Name (optional)</label>
      <input type="text" placeholder="Your company name" [(ngModel)]="getBillingInfo().companyName">
      <div class="errorMsg"></div>
    </li>
    <li>
      <label>Billing Address*</label>
      <input ngx-google-places-autocomplete type="text" name="street" [(ngModel)]="getBillingInfo().street" required #street="ngModel" maxlength="100"
             #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" (keyup)="isAddressPresent($event)" minlength="1" placeholder="Billing Address">
      <div class="errorMsg"></div>
    </li>
  </ul>

  <div class="termsandConditions">
    <h3>Terms & Conditions</h3>
    <div class="listBox">
      <perfect-scrollbar>
        <p><input type="checkbox" [checked]="termsConditions.pointOne" (change)="termsAndConditionsClicked($event, 'pointOne')" id="pointone"> <label for="pointone" class="icon-right"></label> I agree to your <a href="https://bstudio-assets.azureedge.net/assets-builder/Builder%20Studio%20Terms%20and%20Conditions.pdf" target="_blank">Terms and Conditions and mutual non disclosure terms.</a></p>
      <!-- <p><input type="checkbox" [checked]="termsConditions.pointOne" (change)="termsAndConditionsClicked($event, 'pointOne')" id="pointone"> <label for="pointone" class="icon-right"></label> I understand that this is a prototype and that it will only cover the major screens but is not meant to be a fully comprehensive prototype of every interaction.</p>
      <p><input type="checkbox" [checked]="termsConditions.pointTwo" (change)="termsAndConditionsClicked($event, 'pointTwo')" id="pointtwo"> <label for="pointtwo" class="icon-right"></label> I understand that my project(s) (“Buildcard(s)“) is built on a framework of potential or existing reusable features, designs, workflows and templates; and this will be licensed to me on a non exclusive basis upon my final payment; and where applicable I will also be provided a copy of the source code. I agree and understand Builder.ai will be able to use any of its potential or existing reusable parts for other customers.</p>
      <p><input type="checkbox" [checked]="termsConditions.pointThree" (change)="termsAndConditionsClicked($event, 'pointThree')" id="pointthree"> <label for="pointthree" class="icon-right"></label> I understand that whatever is explicitly defined and/or billed for as customizations (design, source code, business logic, workflows or custom features) in my Buildcard(s) will remain my intellectual property and be assigned to me exclusively upon payment. I understand that the aggregate intellectual property of my Buildcard(s) will become my property upon the final payment.</p>
      <p><input type="checkbox" [checked]="termsConditions.pointFour" (change)="termsAndConditionsClicked($event, 'pointFour')" id="pointfour"> <label for="pointfour" class="icon-right"></label> I understand that any timelines given are indicative and Builder.ai will do whatever is reasonably necessary to achieve them but does not guarantee them unless otherwise provided for in writing. I understand that my timely response to questions and approvals is essential to a speedy completion of my Buildcard(s).</p>
      <p><input type="checkbox" [checked]="termsConditions.pointFive" (change)="termsAndConditionsClicked($event, 'pointFive')" id="pointfive"> <label for="pointfive" class="icon-right"></label> I understand that Builder will retain all control of the Intellectual Property except any data entered by me or my company until all contracted payments have been made unless otherwise agreed with Builder.ai in writing. I agree that Builder.ai can delete my data if I do not make payments for 14 days from any due date.</p>
      <p><input type="checkbox" [checked]="termsConditions.pointSix" (change)="termsAndConditionsClicked($event, 'pointSix')" id="pointsix"> <label for="pointsix" class="icon-right"></label> I understand and agree to the terms and conditions as outlined in the Builder.ai (Engineer.ai’s) Master User Terms & License Agreement. I understand and agree to the mutual non disclosure clauses within the agreement.</p> -->
      </perfect-scrollbar>
    </div>
  </div>

  <div class="agreeBtnBlock">
      <button type="button" class="buttonStyle" [ngClass]="{'custom-disabled': (!checkValidity() || (authService.getLoggedInUser().roles === 'CPE') || dataCommService.proceedClickedFromRental)}" (click)="checkValidity() && authService.getLoggedInUser().roles != 'CPE' && updateAddressSignNDAandProceedForPayment()">Agree to Terms & Continue to Payment
        <img *ngIf="dataCommService.proceedClickedFromRental" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
      </button>
      <div class="tooltipBlock" *ngIf="authService.getLoggedInUser().roles === 'CPE'">You cannot proceed further as you have a Productologist account.</div>
  </div>
</div>

<div class="commonPopUp active" *ngIf="isAddreddSelected">
  <div class="popOverlay" (click)="closeModal()"></div>
  <div class="popHolder contractformPopup">
    <div class="closePopUp" (click)="closeModal()"><span class="icon-cancel"></span></div>
    <ul>
      <li><label>First Name*</label> <input type="text" [(ngModel)]="getBillingInfo().firstname">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().firstname">Please enter valid first name</div>
      </li>
      <li><label>Last Name*</label> <input type="text" [(ngModel)]="getBillingInfo().lastname">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().lastname">Please enter valid last name</div>
      </li>
      <!-- <li><label>Phone Number*</label>
        <international-phone-number #mobileNumber="ngModel" name="mobileNumber" (keyup)="onFlagChange()" placeholder="Mobile Number" [(ngModel)]="getBillingInfo().contact"
                                    [maxlength]="20" [defaultCountry]="authService.countryNameCode" required>
        </international-phone-number>
        <div class="errorMsg" *ngIf="mobileNumber.invalid">Please enter valid contact</div>
      </li> -->
      <li><label>Phone Number*</label>
        <ngx-intl-tel-input #mobileNumber="ngModel" name="mobileNumber" [ngModel]="getBillingInfo().contact"
          (ngModelChange)="onMobileNoChange($event)" [cssClass]="'custom'" [selectedCountryISO]="authService.countryNameCode"
          [enablePlaceholder]="true" [searchCountryFlag]="true" [separateDialCode]="true" [maxLength]="20"  (countryChange)="onCountryChange($event)" required>
        </ngx-intl-tel-input>
        <div class="errorMsg" *ngIf="mobileNumber?.invalid">Please enter valid contact</div>
      </li>
      <li><label>Company Name (Optional)</label> <input type="text" [(ngModel)]="getBillingInfo().companyName">
<!--        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().companyName">Please enter valid company name</div>-->
<!--        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().companyName && companyName.invalid">Company name should be minimum of 3 chars long</div>-->
      </li>
      <li>
        <label>Country*</label>
        <div class="selectWrap">
          <div class="valueField" *ngIf="!getBillingInfo().country">Select Country</div>
          <div class="valueField" *ngIf="getBillingInfo().country">{{getBillingInfo().country}}</div>
          <input *ngIf="showCountryDropdown" class="searchCountryInput" placeholder="Search Country"
            (input)="searchCountry($event)">
            <div class="valueDropDown" *ngIf="showCountryDropdown">
            <ng-container *ngIf="countryList?.length; else noRecordFound">
              <div class="valueList" *ngFor="let country of countryList" (click)="selectCountry(country.name)">{{country.name}}
              </div>
            </ng-container>
            <ng-template #noRecordFound>
              <div class="valueList">No Record Found</div>
            </ng-template>
          </div>
            <div class="errorMsg dropdownError" *ngIf="isSaveButtonClicked && !getBillingInfo().country">Please enter valid country</div>
            <div class="errorMsg isCountryZipValid dropdownError" *ngIf="!isCountryZipValid">
            The combination of country and zip code does not exists. Please validate.
          </div>
        </div>
        <!-- <label>Country*</label> <input type="text" [(ngModel)]="getBillingInfo().country" (input)="onInputChange()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().country">Please enter valid country</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().country && country?.invalid">Country should be minimum of 3 chars long</div>
        <div class="errorMsg isCountryZipValid" *ngIf="!isCountryZipValid">The combination of country and zip code does not exists. Please validate.</div> -->
      </li>
      <li><label>Zip Code*</label> <input type="text" [(ngModel)]="getBillingInfo().pincode" (input)="onCountryZipChange()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().pincode || pincode?.invalid)">Please enter valid pincode</div>
      </li>
      <li>
        <label>State/Territory/Region*</label>
        <div class="selectWrap">
          <div class="valueField state" *ngIf="!getBillingInfo().state">Select State</div>
          <div class="valueField state" *ngIf="getBillingInfo().state">{{getBillingInfo().state}}</div>
          <div class="valueDropDown state" *ngIf="showStateDropdown">
            <div class="valueList" *ngFor="let state of stateList" (click)="selectState(state.name)">{{state.name}}</div>
          </div>
          <div class="errorMsg dropdownError" *ngIf="isSaveButtonClicked && !getBillingInfo().state">Please enter valid state</div>
        </div>
        <!-- <label>State/Territory/Region*</label> <input type="text" [(ngModel)]="getBillingInfo().state">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().state">Please enter valid state</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().state && state?.invalid">State should be minimum of 3 chars long</div> -->
      </li>
      <li><label>City*</label> <input type="text" [(ngModel)]="getBillingInfo().city">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().city">Please enter valid city</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().city && city?.invalid">City should be minimum of 3 chars long</div>
      </li>
      <li><label>Street/Locality*</label> <input type="text" [(ngModel)]="getBillingInfo().street">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().street">Please enter valid street</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().street && street?.invalid">Street should be minimum of 3 chars long</div>
      </li>
      <li><label>Apartment/Unit Number*</label> <input type="text" [(ngModel)]="getBillingInfo().apartment">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().apartment">Please enter valid apartment</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().apartment && apartment?.invalid">Apartment should be minimum of 1 char long</div>
      </li>
      <li *ngIf="showGSTIN">
        <label>GST number</label>
        <input type="text" name="gstNumber" [(ngModel)]="getBillingInfo().gstNumber" required
               #gstNumber="ngModel" minlength="1" maxlength="100"
               [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().gstNumber || gstNumber.invalid)}"
               placeholder="eg. 06ABCDE1234F2Z5"
               pattern="^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().gstNumber || gstNumber.invalid)">Please enter valid GST number</div>
      </li>
    </ul>

    <button class="buttonStyle" (click)="submitAddress()">Done</button>
  </div>
</div>
