// tslint:disable: jsdoc-format
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {PreviewModel} from '../../main/shared/models/preview.model';
import {HotspotModel} from '../../main/shared/models/hotspot.model';
import {Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from './constants';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    baseUrl: string;
    public locale = "en-US";
    constructor(private router: Router) {
        this.baseUrl = environment.API_URL;
    }

    updateArrayList(array: Array<any>, updatedFeature) {
        const index = array.findIndex((element) => {
            return element.id === updatedFeature.id;
        });
        array[index] = updatedFeature;
        return array;
    }

    getIndexById(array, searchItem, propertyToCompare): number {
        return array.findIndex((element) => {
            return element[propertyToCompare] === searchItem[propertyToCompare];
        });
    }

    getObjectById(array, item) {
        return array.find(element => {
            return element.id === item.id;
        });
    }

    /**
     * @description method will return absolute path of
     * the uploaded image
     * @param url image url
     */
    checkImageURL(url: string): string {
        if (url && (url !== '' && url !== null)) {
            if (url.indexOf('http') === 0 || url.indexOf('https') === 0) {
                return url;
            } else {
                return `${this.baseUrl}${url}`;
            }
        }
    }

    /**
     * @description encode image url in base62
     * @param url feature image url
     */
    imgToBase64(featureImage) {
        const src = this.checkImageURL(featureImage);
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = src + '?v=' + Math.random();
            image.crossOrigin = '*';
            image.onload = () => {
                const base64 = this.getBase64Image(image);
                resolve(base64);
            };
            image.onerror = err => reject(err);
        });
    }

    /**
     * @description get base 64 images
     * @param img image url
     * @param type image type
     */
    getBase64Image(img, type = "image/png") {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL(type);
        return dataURL;
    }

    cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    copyText(inputElement) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    }

    isEmptyObject(obj) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }

    objectToArray(objData: any): any[] {
        const tempArr = [];
        for (const key in objData) {
            if (objData.hasOwnProperty(key)) {
                tempArr.push(Object.assign({
                    uniq_code: key
                }, objData[key]));
            }
        }
        return tempArr;
    }

    public checkForLinkedLaunchScreen(featureList: PreviewModel[]) {
        let launchIndex = 0;
        let clickableItemCount = 0;
        featureList.forEach((feature: PreviewModel, index) => {
            if (feature.hotspots && feature.hotspots.length > 0) {
                let clickableCount = 0;
                feature.hotspots.forEach((hotspot: HotspotModel) => {
                    if (hotspot.clickable_items && hotspot.clickable_items.length > 0) {
                        clickableCount += hotspot.clickable_items.length;
                    }
                });
                if (clickableCount > clickableItemCount) {
                    clickableItemCount = clickableCount;
                    launchIndex = index;
                }
            }
        });
        return launchIndex;
    }

    redirectTo(uri) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate([uri]));
    }

    findIndex2DArray(array, property, item) {
        let resultIndex;
        array.forEach((arr, ind) => {
            const itemIndex = arr.findIndex(x => x[property] === item);
            if (itemIndex > -1) {
                resultIndex = [ind, itemIndex];
            }
        });
        return resultIndex;
    }

    unsubscribeOnDestroy(subs: Subscription[]) {
        subs.forEach(sub => {
            sub.unsubscribe();
        });
    }

    isSketch(file) {
        if (/(\.sketch)$/i.exec(file)) {
            return true;
        } else {
            return false;
        }
    }
    isPdf(file) {
        if (/(\.pdf)$/i.exec(file)) {
        return true;
        } else {
        return false;
        }
    }
    isSvg(file) {
        if (/(\.svg)$/i.exec(file)) {
        return true;
        } else {
        return false;
        }
    }

    validationOfLayers(pages){
        let validationObject = {pageName:'',artBoardCount:0};
        pages.forEach(page => {
          const layers = page.layers;
          const checkForMultipeArtboard = layers.filter(layer => layer._class == "artboard");
          const artBoard = layers.find(layer => layer._class == "artboard");
          if(artBoard){
           validationObject.pageName = artBoard.name;
           validationObject.artBoardCount += checkForMultipeArtboard.length;
          }
        }); 
     
        return validationObject;
       }
    
      blobCreationFromURL(inputURI,artBoardName) {
        var arr = inputURI.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
    
        while(n--){
          u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], `${artBoardName}.png`, {
          type: mime
        });
      }

    public getLocaledPrice(price) {
        if (price != null) {
          price = String(price);
        }
        if (price != null) {
          if (price.indexOf(",") !== -1) {
            price = price.split(",").join("");
          }
          let priceSliced = [];
          let decimalPart = "00";
          if (price.indexOf(".") != -1) {
            priceSliced = price.split(".");
            price = priceSliced[0];
            decimalPart = priceSliced[1];
          }
          const formatter = new Intl.NumberFormat(this.locale);
          let formatedPrice = formatter.format(price);
          if (formatedPrice.indexOf(".") == -1) {
            formatedPrice =
              formatedPrice + Constants.PARAM_DECIMAL + decimalPart;
          }
          return formatedPrice;
        } else {
          return "0.00";
        }
    }
}
