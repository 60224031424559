import { CommentPipe } from './pipes/comments-search.pipe';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './components/header/header.component';
import { HeaderLogoComponent } from './components/header/header-logo/header-logo.component';
import { HeaderActionsComponent } from './components/header/header-actions/header-actions.component';
import { HighlightDirective } from './directives/highlight.directive';
import { FeatureFrameComponent } from './components/feature-frame/feature-frame.component';
import { MobOtherOptionsComponent } from './components/feature-frame/mob-other-options/mob-other-options.component';

import { SidekickImageListComponent } from './components/sidekick-image-list/sidekick-image-list.component';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG,
   PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { SideToolbarComponent } from './components/side-toolbar/side-toolbar.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { TabsModule } from './modules/tabs/tabs.module';
import { OverlayWithContentComponent } from './components/overlay-with-content/overlay-with-content.component';
import { HeroFrameListingComponent } from './components/hero-frame-listing/hero-frame-listing.component';
import { FeatureFrameEditableComponent } from './components/feature-frame-editable/feature-frame-editable.component';
import { ManualShareService } from './components/manual-hotspot/manual.shared.service';
import { ManualHotspotService } from './components/manual-hotspot/manual-hotspot.service';
import { ManualHotspotDirective } from './components/manual-hotspot/manual-hotspot.directive';
import { ManualHotspotComponent } from './components/manual-hotspot/manual-hotspot.component';
import { RectangleComponent } from './components/manual-hotspot/rectangle-component/rectangle-component';
import { TabComponent } from './modules/tabs/tab/tab.component';
import { TabsComponent } from './modules/tabs/tabs.component';
import { OverlayWithContentService } from './components/overlay-with-content/overlay-with-content.service';
import { SharePrototypeComponent } from './components/share-prototype/share-prototype.component';
import { EmbedShareComponent } from './components/share-prototype/embed-share/embed-share.component';
import { LinkShareComponent } from './components/share-prototype/link-share/link-share.component';
import { MailSMSShareComponent } from './components/share-prototype/mail-sms-share/mail-sms-share.component';
import { SocialShareComponent } from './components/share-prototype/social-share/social-share.component';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { TagInputModule } from 'ngx-chips';
import { DragDropUploadComponent } from './components/drag-drop-upload/drag-drop-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { IndustryTypeComponent } from './components/industry-type/industry-type.component';
import { BindActionDirective } from './directives/bind-action.directive';
import { CustomPrototypePaymentComponent } from './components/custom-prototype-payment/custom-prototype-payment.component';
import { FeatureActionComponent } from './components/feature-action/feature-action.component';
import { FeatureFullScreenComponent } from './components/feature-full-screen/feature-full-screen.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { SyncDataComponent } from './components/sync-data/sync-data.component';
import { CommentPanelComponent } from './components/comments/comment-pannel/comment-panel.component';
import { CommentBoxComponent } from './components/comments/comment-box/comment-box.component';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { AllFeaturesComponent } from './components/all-features/all-features.component';
import {MatCarouselComponent, MatCarouselModule, MatCarouselSlideComponent} from '@ngmodule/material-carousel';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { TimeLabelPipe } from './pipes/time-label.pipe';
import { PrototypeLinkComponent } from './components/prototype-link/prototype-link.component';
import {LoginSignupComponent} from '@shared/components/rental-build-card/right-side/login-signup/login-signup.component';
import {PaymentOverviewComponent} from '@shared/components/rental-build-card/right-side/payment-overview/payment-overview.component';
import {ContractsComponent} from '@shared/components/rental-build-card/right-side/contracts/contracts.component';
import {PaymentOptionsComponent} from '@shared/components/rental-build-card/right-side/payment-options/payment-options.component';
import {ThankuScreenComponent} from '@shared/components/rental-build-card/right-side/thanku-screen/thanku-screen.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {AngularMyDatePickerModule} from 'angular-mydatepicker';
import {RentalBuildCardComponent} from '@shared/components/rental-build-card/rental-build-card.component';
import {LeftSideComponent} from '@shared/components/rental-build-card/left-side/left-side.component';
import {RightSideComponent} from '@shared/components/rental-build-card/right-side/right-side.component';
import {BrandingComponent} from '@shared/components/rental-build-card/right-side/branding/branding.component';
import {SuccessBrandingComponent} from '@shared/components/rental-build-card/right-side/branding/success/success-branding/success-branding.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ForgotPasswordComponent } from './components/rental-build-card/right-side/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/rental-build-card/right-side/reset-password/reset-password.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ReviewScreenComponent } from './components/review-screen/review-screen.component';
import { ReviewScreenPopupComponent } from './components/review-screen-popup/review-screen-popup.component';
import { DescriptionCardComponent } from './components/description-card/description-card.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {HandleApiErrorsComponent} from '../main/handle-api-errors/handle-api-errors.component';
import { FeedbackPopupComponent } from './components/feedback-popup/feedback-popup.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NgxCroppieModule } from './modules/ngx-croppie/ngx-croppie.module';
import { ReleasedPopupComponent } from './components/released-popup/released-popup.component';
import {MaintenanceSupportToastComponent} from '@shared/components/maintenance-support-toast/maintenance-support-toast.component';
import { ActivityModalComponent } from './components/activity-modal/activity-modal.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { EmptyPrototypeComponent } from './components/empty-prototype/empty-prototype.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { HeaderRolesComponent } from './components/header/header-roles/header-roles.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    PerfectScrollbarModule,
    TabsModule,
    FontAwesomeModule,
    InternationalPhoneNumberModule,
    TagInputModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MatCarouselModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    GooglePlaceModule,
    AngularMyDatePickerModule,
    ColorPickerModule,
    DragDropModule,
    NgxCroppieModule,
    ShareButtonsModule.withConfig({}),
    ShareButtonModule,
		NgxIntlTelInputModule
  ],
  declarations: [
    HeaderComponent,
    HeaderLogoComponent,
    HeaderActionsComponent,
    HighlightDirective,
    FeatureFrameComponent,
    MobOtherOptionsComponent,
    SidekickImageListComponent,
    SideToolbarComponent,
    ConfirmationModalComponent,
    ConfirmationModalComponent,
    SpinnerLoaderComponent,
    OverlayWithContentComponent,
    HeroFrameListingComponent,
    SpinnerLoaderComponent,
    FeatureFrameEditableComponent,
    ManualHotspotComponent,
    ManualHotspotDirective,
    RectangleComponent,
    SharePrototypeComponent,
    EmbedShareComponent,
    LinkShareComponent,
    MailSMSShareComponent,
    SocialShareComponent,
    DragDropUploadComponent,
    IndustryTypeComponent,
    BindActionDirective,
    CustomPrototypePaymentComponent,
    FeatureActionComponent,
    FeatureFullScreenComponent,
    ModalPopupComponent,
    SyncDataComponent,
    CommentPanelComponent,
    CommentBoxComponent,
    LogoUploadComponent,
    AllFeaturesComponent,
    RentalBuildCardComponent,
    LeftSideComponent,
    RightSideComponent,
    LoginSignupComponent,
    PaymentOverviewComponent,
    ContractsComponent,
    PaymentOptionsComponent,
    ThankuScreenComponent,
    AllFeaturesComponent,
    NameInitialsPipe,
    TimeLabelPipe,
    AllFeaturesComponent,
    PrototypeLinkComponent,
    BrandingComponent,
    SuccessBrandingComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProfileMenuComponent,
    ReviewScreenComponent,
    ReviewScreenPopupComponent,
    DescriptionCardComponent,
    HandleApiErrorsComponent,
    FeedbackPopupComponent,
    NotificationComponent,
    CommentPipe,
    ReleasedPopupComponent,
    MaintenanceSupportToastComponent,
    ActivityModalComponent,
    EmptyPrototypeComponent,
    HeaderRolesComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ManualShareService,
    ManualHotspotService,
    OverlayWithContentService
  ],
    exports: [
        HeaderComponent,
        HeaderLogoComponent,
        HeaderActionsComponent,
        HighlightDirective,
        FeatureFrameComponent,
        MobOtherOptionsComponent,
        SidekickImageListComponent,
        SideToolbarComponent,
        ConfirmationModalComponent,
        ConfirmationModalComponent,
        SpinnerLoaderComponent,
        OverlayWithContentComponent,
        HeroFrameListingComponent,
        SpinnerLoaderComponent,
        FeatureFrameEditableComponent,
        ManualHotspotComponent,
        ManualHotspotDirective,
        RectangleComponent,
        TabComponent,
        TabsComponent,
        SharePrototypeComponent,
        EmbedShareComponent,
        LinkShareComponent,
        MailSMSShareComponent,
        SocialShareComponent,
        PerfectScrollbarComponent,
        DragDropUploadComponent,
        IndustryTypeComponent,
        BindActionDirective,
        CustomPrototypePaymentComponent,
        FeatureActionComponent,
        FeatureFullScreenComponent,
        ModalPopupComponent,
        SyncDataComponent,
        LogoUploadComponent,
        AllFeaturesComponent,
        MatCarouselComponent,
        MatCarouselSlideComponent,
        MDBBootstrapModule,
        RentalBuildCardComponent,
        LeftSideComponent,
        RightSideComponent,
        LoginSignupComponent,
        PaymentOverviewComponent,
        ContractsComponent,
        PaymentOptionsComponent,
        ThankuScreenComponent,
        CommentPanelComponent,
        CommentBoxComponent,
        MDBBootstrapModule,
        NameInitialsPipe,
        TimeLabelPipe,
        MDBBootstrapModule,
        PrototypeLinkComponent,
        ColorPickerModule,
        ReviewScreenPopupComponent,
        DescriptionCardComponent,
        HandleApiErrorsComponent,
        FeedbackPopupComponent,
        NotificationComponent,
        CommentPipe,
        ReleasedPopupComponent,
        MaintenanceSupportToastComponent,
        ActivityModalComponent,
        EmptyPrototypeComponent
    ],
  entryComponents: [
    RectangleComponent
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fab);
  }
}

