import {Component, OnInit} from '@angular/core';
import moment from 'moment';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import {Router} from '@angular/router';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {DataService} from '../../../../../main/shared/services/data.service';
import {AuthService} from '@core/auth.service';

@Component({
    selector: 'app-thanku-screen',
    templateUrl: './thanku-screen.component.html',
    styleUrls: ['./thanku-screen.component.scss']
})
export class ThankuScreenComponent implements OnInit {
    public specCallData: any;
    public showOrderSummary = false;
    isPaymentTypeSpecing = false;
    transactionId: string;
    isScheduleCall = false;
    isCalenderOpen = false;
    dateChange = false;
    jsDate: any;
    dateString = '';
    day = 1;
    myDpOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'dd.mm.yyyy',
        disableWeekends: true,
        inline: true,
        disableDates: this.getNextThreeDays()
        // other options are here...
    };
    model: IMyDateModel = null;
    slotsArray = ['12:00 AM',
        '01:00 AM',
        '02:00 AM',
        '03:00 AM',
        '04:00 AM',
        '05:00 AM',
        '06:00 AM',
        '07:00 AM',
        '08:00 AM',
        '09:00 AM',
        '10:00 AM',
        '11:00 AM',
        '12:00 PM',
        '01:00 PM',
        '02:00 PM',
        '03:00 PM',
        '04:00 PM',
        '05:00 PM',
        '06:00 PM',
        '07:00 PM',
        '08:00 PM',
        '09:00 PM',
        '10:00 PM',
        '11:00 PM',
        '12:00 AM'];
    endSlotsArray = JSON.parse(JSON.stringify(this.slotsArray));
    public startTimeSlot = this.slotsArray[0];
    public endTimeSlot = this.slotsArray[0];

    constructor(public dataCommService: DataCommService, public dataService: DataService, private router: Router) {
    }

    ngOnInit(): void {
        this.setDateInfo();
        this.dataCommService.trackEvent('On Thank you page', {page_source : 'Custom Prototype'});
    }

    public setDateInfo(): void {
        const today = new Date();
        today.setDate(today.getDate());
        const disableDateUntil = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
        this.myDpOptions = {
            dateRange: false,
            dateFormat: 'dd.mm.yyyy',
            disableUntil: disableDateUntil,
            disableWeekends: true,
            inline: true
        };
        this.model = {isRange: false, singleDate: {jsDate: new Date()}};
    }

    public showHideOrderSummary(isShow) {
        this.showOrderSummary = isShow;
    }

    public getTransactionID() {
        return this.dataCommService.transactionId;
    }

    public getNextThreeDays() {
        return [this.getDateObj(), this.getDateObj(this.day), this.getDateObj(this.day), this.getDateObj(this.day)];
    }

    getDateObj(day?) {
        const today = moment();
        if (day) {
            const tomorrow = moment(today).add(day, 'days');
            const tomorrowday = tomorrow.day();
            if (tomorrowday === 0 || tomorrowday === 6) {
                this.day = day + 1;
                return this.getDateObj(day + 1);
            } else {
                this.day = day + 1;
                return {year: tomorrow.year(), month: tomorrow.month() + 1, day: tomorrow.date()};
            }
        } else {
            const todaydate = today.day();
            if (todaydate === 0 || todaydate === 6) {
                this.day = todaydate + 1;
                return this.getDateObj(todaydate + 1);
            } else {
                return {year: today.year(), month: today.month() + 1, day: today.date()};
            }
        }
    }

    onCalendarToggle(event: number): void {
        if (event === 1) {
            this.isCalenderOpen = true;
        } else {
            this.isCalenderOpen = false;
        }
    }

    onDateChanged(event: IMyDateModel): void {
        this.jsDate = event.singleDate.jsDate;
        this.dateString = moment(event.singleDate.jsDate).format('ll');
        this.dateChange = true;
    }

    saveScheduleDate() {
        this.dataCommService.trackEvent('Schedule Spec Call', {page_source : 'Prototype payment'});
        if (this.dataCommService.buildCardData.id) {
            const obj = {date: this.jsDate, slot_start: this.startTimeSlot, slot_end: this.endTimeSlot};
            this.dataService.addScheduleDate(this.dataCommService.buildCardData.id, obj).subscribe((data) => {
                this.dateChange = false;
                this.dataCommService.showCalendar = false;
                this.dataCommService.buildCardData.scheduled_date = this.jsDate;
                this.dataCommService.handleNextPrevForRentalFlow(5);
            });
        }
    }

    startTimeSlotChanged(value: any) {
        this.startTimeSlot = value;
        this.endSlotsArray = JSON.parse(JSON.stringify(this.slotsArray));
        const startTimeIndex = this.slotsArray.indexOf(this.startTimeSlot);
        this.endSlotsArray = this.endSlotsArray.splice(startTimeIndex + 1, this.slotsArray.length);
        this.endTimeSlot = this.endSlotsArray[0];
    }

    endTimeSlotChanged(value: any) {
        this.endTimeSlot = value;
    }

    closeSpecModal() {
        this.dataCommService.showHidePaymentFlow(false);
        this.dataCommService.showCallBookedSuccess = false;
        this.gotoDashboard();
    }

    gotoDashboard() {
        this.router.navigate(['dashboard']);
    }

    scheduleExertCall() {
        this.dataCommService.showCalendar = true;
        this.dataCommService.schedulerURL = this.dataCommService.sanitizer.bypassSecurityTrustResourceUrl(this.dataCommService.showTimeKitWindow(false, this.dataCommService.buildcardId, 'kickoff'));
        this.dataCommService.trackEvent('Click on Book an expert call', {page_source : 'Custom Prototype'});
    }

    addBranding() {
        // this.dataCommService.showHidePaymentFlow(false);
        this.dataCommService.handleNextPrevForRentalFlow(6);
        this.dataCommService.selectedSectionIndex = 6;
       // this.router.navigate(['/' + this.dataCommService.buildcardId + '/branding']);
    }
    goToFlowchart() {
        this.dataCommService.closeAllOnboarding();
        window.location.href = this.dataCommService.buildcardId + '/v1/dashboard/' + this.dataCommService.platform + '/' + this.dataCommService.platformType + '/flowchart';
    }



}
