<form class="reset-container" [formGroup]="resetPasswordForm" *ngIf="resetPasswordState === 1">
    <ul>
        <li>
            <label>Enter new password*</label>
            <input [ngClass]="{'error': invalidPassword || password.invalid}" type="password" placeholder="Enter new password here" minlength="8" formControlName="password" required>
            <div *ngIf="(password.touched && password.invalid) || (invalidPassword && invalidPasswordErrMeggase)">
                <span *ngIf="password.errors['minlength']" class="errorMsg"> Password should contain 8 characters</span>
                <span *ngIf="password.errors['required']" class="errorMsg"> Password cannot be blank </span>
                <span class="errorMsg" *ngIf="invalidPassword && invalidPasswordErrMeggase">{{ invalidPasswordErrMeggase }}</span>
            </div>
        </li>
    </ul>`
    <button type="button" class="buttonStyle" (click)="resetPassword()">Set new password</button>
</form>
<div class="reset-success" *ngIf="resetPasswordState === 2">
    <div class="success-wrap">
        <span class="success-icon icon-success-tick"></span>
        <h3 class="title">Password changed successfully!</h3>
        <p class="link">Your password successfully changed, now you can sign in with your new password</p>
    </div>
    <button type="button" class="buttonStyle" (click)="goToLogin()">Sign in now</button>
</div>
