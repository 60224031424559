import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ManualShareService {
  // Observable string sources
  private changeManualHotspotImageSource = new Subject<any>();
  private updateHotspotCountSource = new Subject<any>();
  private uncheckSelectedHotspotSource = new Subject<any>();
  private setSuggetionTooltipPosSource = new Subject<any>();
  private addDeleteCarouselImageSource = new Subject<any>();

  // create observables vars
  changeManualHotspotImage$ = this.changeManualHotspotImageSource.asObservable();
  updateHotspotCount$ = this.updateHotspotCountSource.asObservable();
  uncheckSelectedHotspot$ = this.uncheckSelectedHotspotSource.asObservable();
  setSuggetionTooltipPos$ = this.setSuggetionTooltipPosSource.asObservable();
  addDeleteCarouselImageSource$ = this.addDeleteCarouselImageSource.asObservable();

  changeHotspotImageFun(sidekickObj: any) {
    this.changeManualHotspotImageSource.next(sidekickObj);
  }

  updateHotspotCountFun(object: any) {
    this.updateHotspotCountSource.next(object);
  }

  uncheckHotspotFun() {
    this.uncheckSelectedHotspotSource.next();
  }

  setSuggetionTooltipPosFun() {
    this.setSuggetionTooltipPosSource.next();
  }

  addDeleteCarouselImgFun(data) {
    this.addDeleteCarouselImageSource.next(data);
  }
}
