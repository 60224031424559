export class UserModel {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  nda_signed: boolean;
  source: string;
  phone_number: string;
  signup_stage: string;
  address_disabled: boolean;
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  currency: any;
  authtoken: string;
  signature_request_guid: string;
  signature_request_status: string;
  user_profiles: any[];
  address: any;
  confirmed_at: any;
  pm_dashboard_token: any;
  user_type: string;
  showTutorial: boolean;
  frontend_url: string;
  roles: string;
  billing_entity: string;
}
