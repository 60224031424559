import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { ToasterService } from '@core/toaster.service';
import { Constants} from '@shared/services/constants';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public baseUrl;
  public builderPaymentUrl;
  public builderFeaturesUrl;
  public studioBaseUrl;
  public studioUrl;
  public trackerBaseUrl;
  public pmDashboardUrl;
  paymentUrl: string;
  //white board env variables
  public whiteBoardBaseUrl;

  constructor(
    public router: Router,
    public toaster: ToasterService
  ) {
    this.baseUrl = environment.API_URL;
    this.studioBaseUrl = environment.STUDIO_API_URL;
    this.pmDashboardUrl = environment.PM_DASH_URL;
    this.builderPaymentUrl = environment.BUILDER_PAYMENT_URL;
    this.builderFeaturesUrl = environment.BUILDER_FEATURES_URL;
    this.studioUrl = environment.STUDIO_URL;
    this.trackerBaseUrl = environment.TRACKER_API_URL;
    this.paymentUrl = environment.PAYMENT_URL;
    this.whiteBoardBaseUrl = environment.white_board_base_url;
  }

  genericSuccess(request: any, response: any) {
    if (response.body.message) {
      this.toaster.success(response.body.message);
    } else {
      if (request.method.toLocaleLowerCase() === 'delete') {
        this.toaster.success(Constants.toasterMessage.DELETE);
      } else {
        this.toaster.success(Constants.toasterMessage.SUCCESS);
      }
    }
    return response;
  }


  genericError(error: any, isRentalFlow) {
    let sError = '';
    let loginError;
    if (error.error.errors) {
      loginError = Object.keys(error.error.errors).includes('email') || Object.keys(error.error.errors).includes('password');
    }
    try {
      sError = Constants.toasterMessage.ERROR; // JSON.parse(error.error._body).error.message;
    } catch (e) {
      sError = Constants.toasterMessage.ERROR;
    }
    if (!sError) {
      sError = Constants.toasterMessage.ERROR;
    }
    if (!isRentalFlow && error.error.message) {
      this.toaster.error(error.error.message);
    } else if (!isRentalFlow && !loginError && error.error.errors[0] !== 'No comments available.') {
      this.toaster.error(sError);
    }
    else if(error.error.errors[0] == 'No comments available.'){
      this.toaster.error('Corresponding story is not linked to the screen'); 
    }
    return throwError(loginError ? error.error : sError);
  }

  authTokenErrorHndlr(error: any) {
    return throwError(Constants.toasterMessage.INVALID_TOKEN);
  }
  serverDown(error: any) {
    return throwError(Constants.toasterMessage.SERVER_DOWN);
  }

  setQueryparams() {
    const path = window.location.href;
    const params = {};
    path.substr(path.indexOf('?')).split('&').forEach(param => {
      const data = param.split('=');
      params[data[0]] = decodeURIComponent(data[1]);
    });
    return params;
  }

  serverError(error: any) {
    return throwError(error.error.message);
  }
}
