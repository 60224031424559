<div *ngIf="loginFlow">
  <div class="topIcon signInFlow"><img src="../../../../../assets/images/buildernow-logo.svg" alt="" /></div>
<!--  <h2 id="headings" [style.paddingTop]="dataCommService.touchPointSubHeading==='Changed'?'25px':'0'">-->
<!--    {{dataCommService.touchPointHeading}}-->
<!--    <span *ngIf="dataCommService.touchPointSubHeading!=='Changed'">{{dataCommService.touchPointSubHeading}}</span>-->
<!--    <span *ngIf="dataCommService.touchPointSubHeading==='Changed'" style="display: inline;">{{dataCommService.touchPointSubHeading}}</span>-->
<!--  </h2>-->
  <!-- <h2 *ngIf="dataCommService.isCommentSigninSignUpForm">Sign up to add your <span>comments here</span></h2> -->
  <div class="leftBottom"><img src="../../../../../assets/images/leftside5.png" /></div>
</div>

<div *ngIf="!loginFlow">
  <div class="topIcon" *ngIf="dataCommService.selectedSectionIndex === 1"><img src="../../../../../assets/images/buildernow-logo.svg" alt="" /></div>

  <ul class="stepsNumber" *ngIf="!(dataCommService.selectedSectionIndex === 1)">
    <!--<li [ngClass]="dataCommService.selectedSectionIndex === 4 ? 'currentSelected':'clickable'"><em
            class="icon-tick"></em><span>5</span></li>-->
    <li [ngClass]="(dataCommService.selectedSectionIndex === 5 || dataCommService.selectedSectionIndex === 6) ? 'currentSelected':'clickable'"
        (click)="handlePaymentoption(3)"><em class="icon-tick"></em><span>4</span></li>
    <li [ngClass]="dataCommService.selectedSectionIndex === 4 ? 'currentSelected':'clickable'"
        (click)="handleContractClick(2)"><em class="icon-tick"></em><span>3</span></li>
    <li [ngClass]="dataCommService.selectedSectionIndex === 3 ? 'currentSelected':'clickable'"
        (click)="handlePaymentOverViewClick(1)"><em class="icon-tick"></em><span>2</span></li>
    <li [ngClass]="dataCommService.selectedSectionIndex === 2 ? 'currentSelected':'clickable'"><em class="icon-tick"></em>
      <span>1</span></li>
  </ul>

  <div class="title">
      <h2 *ngIf="dataCommService.selectedSectionIndex === 1">Make yours unique</h2>
      <h2 *ngIf="dataCommService.selectedSectionIndex === 2">Our contract to you</h2>
      <h2 *ngIf="dataCommService.selectedSectionIndex === 3">Payment options</h2>
      <h2 *ngIf="dataCommService.selectedSectionIndex === 4">Book kickoff call with our expert</h2>
      <h2 *ngIf="dataCommService.selectedSectionIndex === 6">Add Branding</h2>
  </div>

  <div class="leftBottom"  *ngIf="!dataCommService.showViewUnlimitedVersion"><img src="../../../../../assets/images/leftside2.png" /></div>
  <div class="leftBottom dfsdff" [@fadeInOut]  *ngIf="dataCommService.showViewUnlimitedVersion"><img src="../../../../../assets/images/leftside-new.png" /></div>
</div>
