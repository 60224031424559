import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {environment} from '@env/environment';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {DataService} from '../../../../../main/shared/services/data.service';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {AuthService} from '@core/auth.service';
import {UserModel} from '@shared/models/user.model';
import { debounceTime } from 'rxjs/operators';
import * as  lookup from 'country-code-lookup';
import countryStateData from '../../../../../../assets/data/country-state';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

  isAddreddSelected = false;
  isSaveButtonClicked = false;
  @ViewChild('form', { static: false }) form;
  showGSTIN = false;
  isGSTCheckboxChecked = false;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild('mobileNumber', { static: false }) public mobileNumber;
  waitingForUpdate = false;
  termsConditions = {pointOne: false, pointTwo: false, pointThree: false, pointFour: false, pointFive: false, pointSix: false};
  addressSubmitted = false;
  isCountryZipValid: boolean = true;
  countryZipChange: Subject<any> = new Subject();
  countryList: any = [];
  stateList: any = [];
  showCountryDropdown: boolean = false;
  showStateDropdown: boolean = false;
  countryZipChangeSub: Subscription;
  apiSub: Subscription[] = [];

  constructor(public dataService: DataService,
              public dataCommService: DataCommService, public authService: AuthService) {
    if (authService.getLoggedInUser()) {
      dataCommService.billingInfo.firstname = this.authService.getLoggedInUser().first_name;
      dataCommService.billingInfo.lastname = this.authService.getLoggedInUser().last_name;
      dataCommService.billingInfo.email = authService.getLoggedInUser().email;
      dataCommService.billingInfo.billing_entity = authService.getLoggedInUser().billing_entity;
      if(!authService.getLoggedInUser().address) authService.countryNameCode = 'in';
      this.setUserAddress();
    }
  }

  @HostListener('document:mousedown', ['$event.target'])
  public onMouseDownFun(targetElement) {
    if (targetElement.classList.contains('valueField')) {
      if (targetElement.classList.contains('state')) {
        this.showCountryDropdown = false
        this.showStateDropdown = !this.showStateDropdown;
      }
      else {
        this.showStateDropdown = false;
        this.countryList = countryStateData;
        this.countryList.sort(this.sortAlphabeticallyByField('name'));
        this.showCountryDropdown = !this.showCountryDropdown;
      }
    }
    else if (!targetElement.classList.contains('valueList') && !targetElement.classList.contains('valueDropDown') && !targetElement.classList.contains('searchCountryInput')) {
      this.showStateDropdown = false;
      this.showCountryDropdown = false;
    }
  }

  ngOnInit(): void {
    this.dataCommService.trackEvent('[Builder] Store Billing/Contracts Page', {page_source : 'Builder Now'});
    this.checkIfAddressSubmitted();
  }

  proceedForPayment() {
    let paymentData: any = {
      'apptoken': environment.PAYMENT_APP_TOKEN,
      'payment_data': {
        'amount': this.dataCommService.prototypeInstallment.installment_amount + this.dataCommService.prototypeInstallment.tax_amount,
        'billing_address_attributes': {
          'billing_address': this.dataCommService.billingInfo.address,
          'billing_city': this.dataCommService.billingInfo.city,
          'billing_name': this.dataCommService.billingInfo.firstname + ' ' + this.dataCommService.billingInfo.lastname,
          'billing_state': this.dataCommService.billingInfo.state,
          'billing_tel': this.dataCommService.billingInfo.contact,
          'billing_zip': this.dataCommService.billingInfo.pincode
        },
        'billing_entity': this.authService.getLoggedInUser().billing_entity,
        'capture': 'true',
        'card_notify_url': environment.STUDIO_API_URL + 'payment_billing/builder_card_owners/card_status',
        'currency': this.dataCommService.prototypeInstallment.currency.code,
        'description': 'Payment for Studio Store',
        'email': this.authService.getLoggedInUser().email,
        'frontend_redirect_url': '',
        'payment_gateway': this.dataCommService.prototypeInstallment.currency.code === 'INR' ? 'ccavenue' : 'stripe',
        'payment_mode': 'complete',
        'payment_notify_url': environment.STUDIO_API_URL + '/payment_billing/installments/status',
        'redirect_billing_details': `${window.location.origin}/buildcard-payment/${this.dataCommService.buildCardUniqueCode}/billingdetails`,
        'redirect_dashboard_url': window.location.origin + '/dashboard',
        'redirect_payment_plan': `${window.location.origin}/buildcard-payment/${this.dataCommService.buildCardUniqueCode}/paymentplan`,
        'redirect_url': '',
        'source': 'Engineer.ai',
        'installment_type': this.dataCommService.prototypeInstallment.installment_type,
        'source_model_guid': this.dataCommService.prototypeInstallment.id,
        'source_model_name': 'Installment',
        'sub_total_amount': this.dataCommService.prototypeInstallment.installment_amount,
        'tax_amount': this.dataCommService.prototypeInstallment.tax_amount
      }
    };
    this.dataService.sendPaymentDetailsAndPoll(paymentData).subscribe(data => {
      this.dataCommService.paymentUrl = data['url'];
      this.dataCommService.startPollingForPayment();
      this.dataCommService.handleNextPrevForRentalFlow();
      this.waitingForUpdate = false;
    }, error => {
      this.dataCommService.proceedClickedFromRental = false;
    });

  }

  public getBuildCardData() {
    return this.dataCommService.buildCardData;
  }

  getBillingInfo() {
    this.showGSTIN = (this.dataCommService.billingInfo && this.dataCommService.billingInfo.country && (this.dataCommService.billingInfo.country.toLowerCase() === 'india')) ? true : false;
    if (!this.showGSTIN) {
      this.dataCommService.billingInfo.gstNumber = '';
      this.isGSTCheckboxChecked = false;
    }
    if (this.dataCommService.billingInfo.street) {
      this.dataCommService.billingInfo.street = decodeURIComponent(this.dataCommService.billingInfo.street);
    }
    if (this.dataCommService.billingInfo.apartment) {
      try {
        this.dataCommService.billingInfo.apartment = decodeURIComponent(this.dataCommService.billingInfo.apartment);
      } catch (e) {

      }
    }
    Object.keys(this.dataCommService.billingInfo).map((key) => {
      if (this.dataCommService.billingInfo[key] && ((this.dataCommService.billingInfo[key] === 'null') || (this.dataCommService.billingInfo[key] === undefined))) {
        this.dataCommService.billingInfo[key] = '';
      }
      if (this.dataCommService.billingInfo[key]) {
        this.dataCommService.billingInfo[key] = this.dataCommService.billingInfo[key].trim();
      }
      return this.dataCommService.billingInfo[key];
    });
    return this.dataCommService.billingInfo;
  }

  handleAddressChange(address: Address) {
    this.isCountryZipValid = true;
    this.getFormattedAddress(address);
  }

  getFormattedAddress(place) {
    const billingInfo = this.dataCommService.billingInfo;
    billingInfo.street = this.placesRef['el'].nativeElement.value;
    billingInfo.pincode = '';
    billingInfo.state = '';
    billingInfo.country = '';
    billingInfo.city = '';
    billingInfo.apartment = '';
    for (const i in place.address_components) {
      const item = place.address_components[i];
      if (item['types'].indexOf('locality') > -1) {
        billingInfo.city = item['long_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        billingInfo.state = item['long_name'];
      } else if (item['types'].indexOf('country') > -1) {
        billingInfo.country = item['long_name'];
      } else if (item['types'].indexOf('postal_code') > -1) {
        billingInfo.pincode = item['short_name'];
      } else if ((item['types'].indexOf('sublocality') > -1) && !billingInfo.city) {
        billingInfo.city = item['short_name'];
      }
    }
    billingInfo.email = this.authService.getLoggedInUser().email;
    billingInfo.contact = this.authService.getLoggedInUser().phone_number ? this.authService.getLoggedInUser().phone_number : billingInfo.contact;
    billingInfo.firstname = this.authService.getLoggedInUser().first_name;
    billingInfo.lastname = this.authService.getLoggedInUser().last_name;
    this.isAddreddSelected = true;
    this.countryZipChangeSub = this.countryZipChange.pipe(debounceTime(500)).subscribe(_ => {
      this.apiSub.push(
        this.dataService.getDetailsByZipCode(this.getBillingInfo().pincode, lookup.byCountry(this.getBillingInfo().country).iso2).subscribe((res: any) => {
          if (res.result.length) {
            this.isCountryZipValid = true;
            this.getBillingInfo().state = res.result[0].state;
            this.getBillingInfo().city = lookup.byCountry(this.getBillingInfo().country).iso2 === 'IN' ? res.result[0].province ? res.result[0].province : res.result[0].district : res.result[0].postalLocation;
          }
          else if (!res.status) this.isCountryZipValid = true;
          else this.isCountryZipValid = false;
        }));
    });
    this.countryList = countryStateData;
    this.countryList.sort(this.sortAlphabeticallyByField('name'));
    if (this.getBillingInfo().country) this.selectCountry(this.getBillingInfo().country, true);
  }

  isAddressPresent(e) {
    if (!this.getBillingInfo().street) {
      this.addressSubmitted = false;
    }
  }

  setUserAddress() {
    const userAddress = this.authService.getLoggedInUser().address,
      billingInfo = this.dataCommService.billingInfo;
    billingInfo.contact = userAddress ? userAddress.phone : '';
    billingInfo.street = userAddress ? decodeURIComponent(userAddress.street) : '';
    billingInfo.apartment = userAddress ? decodeURIComponent(userAddress.apartment) : '';
    billingInfo.state = userAddress ? userAddress.state : '';
    billingInfo.city = userAddress ? userAddress.city : '';
    billingInfo.pincode = userAddress ? userAddress.pincode : '';
    billingInfo.country = userAddress ? userAddress.country : '';
    billingInfo.companyName = userAddress ? userAddress.company_name : '';
    billingInfo.gstNumber = userAddress ? userAddress.gst_number : '';
    billingInfo.firstname = userAddress && userAddress.first_name ? userAddress.first_name : billingInfo.firstname;
    billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
    if (billingInfo.gstNumber && billingInfo.companyName) {
      this.isGSTCheckboxChecked = true;
    }
    if (this.dataCommService.buildCardData && this.dataCommService.buildCardData.studio_store_nda_signed) {
      this.termsConditions.pointOne = true;
      this.termsConditions.pointTwo = true;
      this.termsConditions.pointThree = true;
      this.termsConditions.pointFour = true;
      this.termsConditions.pointFive = true;
      this.termsConditions.pointSix = true;
    }
  }

  checkIfFormValid() {
    for (const key of Object.keys(this.dataCommService.billingInfo)) {
      if (((this.dataCommService.billingInfo[key] === '') && (((key !== 'name') && (key !== 'billing_entity') && (key !== 'companyName') && (key !== 'address') && (key !== 'gstNumber')))) || (this.mobileNumber && this.mobileNumber.invalid)) {
        return false;
        break;
      }
    }
    return true;
  }

  submitAddress() {
    const billingInfo = this.dataCommService.billingInfo;
    this.isSaveButtonClicked = true;
    if (!this.checkIfFormValid()) {
      return;
    }
    this.formatMobileNo();
    this.isSaveButtonClicked = false;
    this.isAddreddSelected = false;
    this.addressSubmitted = true;
  }

  updateAddressSignNDAandProceedForPayment() {
    this.dataCommService.trackEvent('Billing/Contracts Terms & conditions CTA clicked', {page_source : 'Builder Now'});
    if (!this.waitingForUpdate) {
      const billingInfo = this.dataCommService.billingInfo;
      this.isSaveButtonClicked = true;
      if (!this.checkIfFormValid()) {
        return;
      }
      this.waitingForUpdate = true;
      this.dataCommService.proceedClickedFromRental = true;
      this.dataCommService.billingInfo.name = this.dataCommService.billingInfo.firstname + (this.dataCommService.billingInfo.lastname ? ' ' + this.dataCommService.billingInfo.lastname : '');
      this.dataCommService.billingInfo.address = this.dataCommService.billingInfo.apartment + (this.dataCommService.billingInfo.street ? ' ' + this.dataCommService.billingInfo.street : '');
      billingInfo.state = billingInfo.state['code'] ? billingInfo.state['name'] : billingInfo.state;
      const ndaSubscription = this.dataService.updateNDA(this.dataCommService.buildcardId);
      const addressSubscription = this.dataService.updateUserAddress(this.dataCommService.billingInfo);
      forkJoin([ndaSubscription, addressSubscription]).subscribe((res) => {
        let userObj: UserModel = new UserModel();
        const userData = this.dataCommService.getMappedModel(userObj, res[1]);
        this.authService.setCookie(userData['user']);
        this.authService.setUserProfile();
        this.isAddreddSelected = false;
        // this.phaseService.addressSubmitted = true;
        this.proceedForPayment();
      }, error => {
        this.dataCommService.proceedClickedFromRental = false;
      });
    }
  }

  termsAndConditionsClicked(event, checkBoxNO) {
    event.stopPropagation();
    if (event.target.checked) {
      this.termsConditions[checkBoxNO] = true;
    } else {
      this.termsConditions[checkBoxNO] = false;
    }
  }

  checkValidity() {
    return (this.addressSubmitted || this.authService.getLoggedInUser().address_disabled) && this.termsConditions.pointOne;
    // && this.termsConditions.pointTwo && this.termsConditions.pointThree && this.termsConditions.pointFour && this.termsConditions.pointFive && this.termsConditions.pointSix
  }

  closeModal() {
    this.isAddreddSelected = false;
    this.countryZipChangeSub.unsubscribe();
  }

  checkIfAddressSubmitted() {
    if (!(this.authService.getLoggedInUser() && this.authService.getLoggedInUser().address &&
        this.authService.getLoggedInUser().address.first_name && this.authService.getLoggedInUser().address.last_name &&
        this.authService.getLoggedInUser().address.phone && this.authService.getLoggedInUser().address.street &&
        this.authService.getLoggedInUser().address.apartment && this.authService.getLoggedInUser().address.state &&
        this.authService.getLoggedInUser().address.city && this.authService.getLoggedInUser().address.pincode && this.authService.getLoggedInUser().address.country)) {
      this.addressSubmitted = false;
    } else {
      this.addressSubmitted = true;
    }
  }

  onFlagChange() {
    if (this.mobileNumber && this.mobileNumber.valid && this.mobileNumber.valueAccessor) {
      this.mobileNumber.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.dialCode;
    }
  }

  onCountryZipChange() {
    if (this.getBillingInfo().country && this.getBillingInfo().pincode) {
      this.getBillingInfo().country = this.toTitleCase(this.getBillingInfo().country);
      if (lookup.byCountry(this.getBillingInfo().country)) this.countryZipChange.next();
      else this.isCountryZipValid = false;
    }
    else this.isCountryZipValid = true;
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  ngOnDestroy() {
    this.apiSub.forEach(e => e.unsubscribe());
  }

  onMobileNoChange(event) {
    if (event) {
      this.getBillingInfo().contact = event.number;
      if (this.mobileNumber && this.mobileNumber.valid && this.mobileNumber.valueAccessor)
        this.mobileNumber.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.dialCode;
    }
    else this.getBillingInfo().contact = '';
  }

  formatMobileNo() {
    this.dataCommService.billingInfo.contact = this.dataCommService.billingInfo.contact.replace(/[\s-]+/g, '');
    this.dataCommService.billingInfo.contact = '+' + this.mobileNumber.countryNameCode + '-' + this.dataCommService.billingInfo.contact;
  }

  onCountryChange(event) {
    if (this.mobileNumber) setTimeout(() => this.getBillingInfo().contact = '');
  }

  selectCountry(countryName, preSelected?) {
    this.getBillingInfo().country = countryName;
    let index = countryStateData.findIndex(e => e.name === countryName);
    this.stateList = countryStateData[index].stateProvinces;
    this.stateList.sort(this.sortAlphabeticallyByField('name'));
    if (!preSelected) {
      this.getBillingInfo().state = '';
      this.onCountryZipChange();      
    }
    this.showCountryDropdown = false;
  }

  sortAlphabeticallyByField(field) {
    return (a, b) => {
      if (a[field] < b[field]) { return -1; }
      if (a[field] > b[field]) { return 1; }
      return 0;
    }
  }

  selectState(state) {
    this.getBillingInfo().state = state;
    this.showStateDropdown = false;
  }

  searchCountry(event) {
    this.countryList = countryStateData.filter(e => e.name.toLowerCase().startsWith(event.target.value.toLowerCase()));
    this.countryList.sort(this.sortAlphabeticallyByField('name'));
  }
}
