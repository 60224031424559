<div class="paymentOptions" *ngIf="dataCommService.paymentUrl">
  <iframe #iframe
    frameborder="0">
  </iframe>
</div >
<!--<div class="paymentOptions" *ngIf="(dataCommService.prototypeInstallment.currency.code === 'INR')">
  <div class="paymentLink">
    <div class="ccavenueTab"><img src="../../../../../../assets/images/ccavenue.png" alt="" > <em class="icon-tick"></em></div>

    <div class="totalRow">
      <p><span>Security Deposit</span> <strong>{{ getCurrency() }}{{ getDeposit() }}</strong></p>
      <h3><span>Total</span> <strong>{{ getCurrency() }}{{ getTotalAmount() }}</strong></h3>
    </div>

    <button type="button" class="buttonStyle" (click)="openInNewTab(isNavigationClicked = true)">Pay & Schedule Kick Off Call</button>
  </div>

</div>-->

<div class="paymentOptions" *ngIf="!dataCommService.paymentUrl">
  <div class="sectionDotloader">
    <div class="dot-flashing"></div>
  </div>
</div>
